//
//  Repeater.ts
//
//  Created by Peter Vu on 2022-05-06 17:04:50.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import {
    Audio,
    FilePreview,
    RepeatableElement,
    Repeater,
    Video,
    WebLink,
} from '@appformula/app-descriptor'
import { Nullable } from '@appformula/shared-foundation-x'
import { computed, makeObservable } from 'mobx'
import { BasePageComponentStore } from './Base'
import { PageComponentRepository } from './Base.repo'

export type RepeatableElementType =
    | 'video'
    | 'audio'
    | 'filePreview'
    | 'weblink'
export type RepeaterRepository = PageComponentRepository<Repeater>

export class RepeaterStore extends BasePageComponentStore<
    Repeater,
    RepeaterRepository
> {
    get element(): Nullable<RepeatableElement> {
        return this.object?.element
    }

    constructor(repository: RepeaterRepository) {
        super(repository)
        makeObservable(this, {
            element: computed.struct,
        })
    }

    setVideoProp<K extends keyof Video>(key: K, value: Video[K]): void {
        this.repository.update('element', {
            ...(this.element as Video),
            [key as keyof Video]: value,
        })
        return
    }

    setAudioProp<K extends keyof Audio>(key: K, value: Audio[K]): void {
        this.repository.update('element', {
            ...(this.element as Audio),
            [key as keyof Audio]: value,
        })
        return
    }

    setFilePreviewProp<K extends keyof FilePreview>(
        key: K,
        value: FilePreview[K]
    ): void {
        this.repository.update('element', {
            ...(this.element as FilePreview),
            [key as keyof FilePreview]: value,
        })
        return
    }

    setWeblinkProp<K extends keyof WebLink>(key: K, value: WebLink[K]): void {
        this.repository.update('element', {
            ...(this.element as WebLink),
            [key as keyof WebLink]: value,
        })
        return
    }

    setElementType(elementType: RepeatableElementType): void {
        return
    }
}
