//
//  InlineCollection.ts
//
//  Created by Peter Vu on 2022-05-06 17:04:23.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import {
    InlineCollection,
    InlineCollectionGridLayout,
    InlineCollectionHorizontalLayout,
    InlineCollectionLayout,
    InlineCollectionLayoutType,
    InlineCollectionVerticalLayout,
} from '@appformula/app-descriptor'
import { computed, makeObservable } from 'mobx'
import { CollectionComponentRepository, CollectionComponentStore } from './Base'

export type InlineCollectionRepository =
    CollectionComponentRepository<InlineCollection>

export class InlineCollectionStore extends CollectionComponentStore<
    InlineCollection,
    InlineCollectionRepository
> {
    get activeLayoutType(): InlineCollectionLayoutType {
        return this.object?.activeLayoutType ?? 'vertical'
    }

    get activeLayout(): InlineCollectionLayout {
        // TODO:
        // throw Error('')
        switch (this.activeLayoutType) {
            case 'grid':
                return this.object?.gridLayout
            case 'horizontal':
                return this.object?.horizontalLayout
            case 'vertical':
                return this.object?.verticalLayout
        }
    }

    constructor(repository: InlineCollectionRepository) {
        super(repository)
        makeObservable(this, {
            activeLayoutType: computed,
            activeLayout: computed,
        })
    }

    setGridLayoutProp<K extends keyof InlineCollectionGridLayout>(
        key: K,
        value: InlineCollectionGridLayout[K]
    ): void {
        this.repository.update('gridLayout', {
            ...(this.activeLayout as InlineCollectionGridLayout),
            [key as keyof InlineCollectionGridLayout]: value,
        })
        return
    }

    setVerticalLayoutProp<K extends keyof InlineCollectionVerticalLayout>(
        key: K,
        value: InlineCollectionVerticalLayout[K]
    ): void {
        this.repository.update('verticalLayout', {
            ...(this.activeLayout as InlineCollectionVerticalLayout),
            [key as keyof InlineCollectionVerticalLayout]: value,
        })
        return
    }

    setHorizontalLayoutProp<K extends keyof InlineCollectionHorizontalLayout>(
        key: K,
        value: InlineCollectionHorizontalLayout[K]
    ): void {
        this.repository.update('horizontalLayout', {
            ...(this.activeLayout as InlineCollectionHorizontalLayout),
            [key as keyof InlineCollectionHorizontalLayout]: value,
        })
        return
    }
}
