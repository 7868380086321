//
//  DateTime.ts
//
//  Created by Peter Vu on 2022-05-06 17:02:53.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { Binding, DateTimeFormInput, DateTimeFormInputType, FormulaValidator } from '@appformula/app-descriptor'
import { Nullable } from '@appformula/shared-foundation-x'
import { computed, makeObservable } from 'mobx'
import { BaseFormInputStore, BaseFormInputRepository } from './Base'

export type DateTimeFormInputRepository = BaseFormInputRepository<DateTimeFormInput>

export class DateTimeFormInputStore extends BaseFormInputStore<DateTimeFormInput, DateTimeFormInputRepository> {
    get dateInputType(): DateTimeFormInputType {
        return this.object?.dateInputType ?? 'dateTime'
    }

    get defaultValue(): Nullable<Binding<Date>> {
        return this.object?.defaultValue
    }

    get formulaValidator(): Nullable<FormulaValidator> {
        return this.object?.formulaValidator
    }

    constructor(repository: DateTimeFormInputRepository) {
        super(repository)
        makeObservable(this, {
            defaultValue: computed.struct,
            dateInputType: computed,
            formulaValidator: computed.struct,
        })
    }

    setValidatorProp<K extends keyof FormulaValidator>(key: K, value: FormulaValidator[K]): void {
        throw new Error('Method not implemented.')
    }
}
