//
//  NumberSliderInput.ts
//
//  Created by Peter Vu on 2022-05-06 17:04:38.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { Binding, NumberSliderInput } from '@appformula/app-descriptor'
import { Nullable } from '@appformula/shared-foundation-x'
import { computed, makeObservable } from 'mobx'
import { ContentExportableComponentStore } from '.'
import { ControlComponentStore } from './Base'
import { PageComponentRepository } from './Base.repo'

export type NumberSliderInputRepository =
    PageComponentRepository<NumberSliderInput>

export class NumberSliderInputStore
    extends ControlComponentStore<NumberSliderInput>
    implements ContentExportableComponentStore
{
    get title(): Nullable<Binding<string>> {
        return this.object?.title
    }

    get maxValue(): Nullable<number> {
        return this.object?.maxValue
    }

    get minValue(): Nullable<number> {
        return this.object?.minValue
    }

    get initialValue(): Nullable<Binding<number>> {
        return this.object?.initialValue
    }

    constructor(repository: NumberSliderInputRepository) {
        super(repository)
        makeObservable(this, {
            title: computed.struct,
            initialValue: computed.struct,
            maxValue: computed,
            minValue: computed,
        })
    }
    get exportPropNames(): string[] {
        return ['title', 'maxValue', 'minValue', 'initialValue']
    }
    exportedData(props: keyof NumberSliderInput): string[] {
        return [this.componentId, props]
    }
}
