//
//  ObjectRepository.ts
//
//  Created by Peter Vu on 2022-05-25 10:52:32.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import {
    map,
    Observable,
    tap,
    share,
    startWith,
    merge,
    from,
    filter,
    of,
    catchError,
} from 'rxjs'
import { TypedDBReference } from './helpers/TypedDBReference'
import * as rxFirebase from '@appformula/shared-rx-firebase'
import { Nullable } from '@appformula/shared-foundation-x'
import * as localForage from 'localforage'
import { docData } from '@appformula/shared-rx-firebase'

export class AnyObservableObjectRepository<O> {
    get objectSnapshot(): Nullable<O> {
        return this._snapshot
    }

    get object(): Observable<O> {
        return this.objectObservable
    }

    readonly objectId: string

    private objectObservable: Observable<O>
    private _snapshot: Nullable<O>

    constructor(
        objectObservable: Observable<O>,
        initialObject: Nullable<O>,
        objectId: string
    ) {
        this._snapshot = initialObject
        this.objectId = objectId
        const objectCacheName = `__FirebaseObject_${objectId}`

        let cachedItem: Observable<O>
        if (initialObject) {
            cachedItem = of(initialObject)
        } else {
            cachedItem = from(localForage.getItem(objectCacheName)).pipe(
                map((e) => e as unknown as O),
                startWith(initialObject),
                filter((e) => e !== undefined),
                filter(isDefined),
                catchError(() => {
                    return of(undefined)
                })
            )
        }

        const firebaseObject = objectObservable.pipe(
            tap({
                next: (newPage) => {
                    this._snapshot = newPage
                    localForage.setItem(objectCacheName, newPage).catch(() => {
                        return
                    })
                },
            })
        )

        this.objectObservable = merge(cachedItem, firebaseObject).pipe(share())
    }
}

export class FirestoreDocObjectRepository<
    O
> extends AnyObservableObjectRepository<O> {
    constructor(
        docRef: firebase.default.firestore.DocumentReference,
        initialObject: Nullable<O>,
        objectId: string
    ) {
        const docObject = docData(docRef).pipe(
            map((data) => data as unknown as O)
        )
        super(docObject, initialObject, objectId)
    }
}

export class FirebaseRTDObjectRepository<
    O
> extends AnyObservableObjectRepository<O> {
    private dbRef: firebase.default.database.Reference
    protected ref: TypedDBReference<O>

    constructor(
        dbRef: firebase.default.database.Reference,
        initialObject: Nullable<O>,
        objectId: string
    ) {
        const objectObservable = rxFirebase.dataSnapshot(dbRef).pipe(
            map((snapshot) => {
                return snapshot.val() as unknown as O
            })
        )

        super(objectObservable, initialObject, objectId)
        this.dbRef = dbRef
        this.ref = new TypedDBReference<O>(dbRef)
    }

    set<K extends keyof O>(key: K, value: O[K]): Promise<void> {
        if (value) {
            return this.dbRef.child(String(key)).set(value)
        } else {
            return this.dbRef.child(String(key)).remove()
        }
    }
}

export function isDefined<T>(
    arg: T | null | undefined
): arg is T extends null | undefined ? never : T {
    return arg !== null && arg !== undefined
}
