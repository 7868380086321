//
//  CollectionPageRepository.ts
//
//  Created by Peter Vu on 2022-05-06 17:01:17.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import {
    CalendarLayout,
    CardLayout,
    CollectionPage,
    ColumnBinding,
    ColumnBindingClause,
    Filter,
    FilterClause,
    FilterType,
    FormulaFilterBinding,
    ItemAction,
    LayoutType,
    ListLayout,
    MapLayout,
    SearchConfig,
    SortColumn,
    SortOrder,
} from '@appformula/app-descriptor'
import {
    CollectionPageItemActionRepository,
    CollectionPageRepository,
    CollectionPageSearchBarRepository,
} from '@appformula/app-studio-core'
import { nanoid } from 'nanoid'
import { FirebaseRTDObjectRepository } from '../../ObjectRepository'
import { FirebaseBasePageRepository } from '../BaseRepository'

export class FirebaseCollectionPageRepository
    extends FirebaseBasePageRepository<CollectionPage>
    implements CollectionPageRepository
{
    addItemAction(itemActionId: string, action: ItemAction): Promise<void> {
        return this.ref.child('itemActions').child(itemActionId).set(action)
    }

    removeItemAction(itemActionId: string): Promise<void> {
        return this.ref.child('itemActions').child(itemActionId).remove()
    }

    setItemActionTitle(itemActionId: string, title: string): Promise<void> {
        return this.ref
            .child('itemActions')
            .child(itemActionId)
            .child('title')
            .set(title)
    }

    setActiveLayoutType(layoutType: LayoutType): Promise<void> {
        return this.ref
            .child('layout')
            .child('activeLayoutType')
            .set(layoutType)
    }

    setCardLayout(layout: CardLayout): Promise<void> {
        return this.ref.child('layout').child('cardLayout').set(layout)
    }

    setCardLayoutProp<K extends keyof CardLayout>(
        key: K,
        value: NonNullable<CardLayout[K]>
    ): Promise<void> {
        return this.ref
            .child('layout')
            .child('cardLayout')
            .child(key)
            .set(value)
    }

    setMapLayout(layout: MapLayout): Promise<void> {
        return this.ref.child('layout').child('mapLayout').set(layout)
    }

    setMapLayoutProp<K extends keyof MapLayout>(
        key: K,
        value: NonNullable<MapLayout[K]>
    ): Promise<void> {
        return this.ref.child('layout').child('mapLayout').child(key).set(value)
    }

    setCalendarLayout(layout: CalendarLayout): Promise<void> {
        return this.ref.child('layout').child('calendarLayout').set(layout)
    }

    setCalendarLayoutProp<K extends keyof CalendarLayout>(
        key: K,
        value: NonNullable<CalendarLayout[K]>
    ): Promise<void> {
        return this.ref
            .child('layout')
            .child('calendarLayout')
            .child(key)
            .set(value)
    }

    setListLayout(layout: ListLayout): Promise<void> {
        return this.ref.child('layout').child('listLayout').set(layout)
    }

    setListLayoutProp<K extends keyof ListLayout>(
        key: K,
        value: NonNullable<ListLayout[K]>
    ): Promise<void> {
        return this.ref
            .child('layout')
            .child('listLayout')
            .child(key)
            .set(value)
    }

    insertSortColumn(
        columnId: string,
        sortOrder: SortOrder,
        rank: string
    ): Promise<void> {
        return this.ref.runTransaction((data) => {
            const preSort = data.preSort ?? {}
            const positions = data.preSortPosition ?? {}
            const preSortId = nanoid(8)
            preSort[preSortId] = {
                columnName: columnId,
                order: sortOrder,
            }
            positions[preSortId] = rank
            data.preSort = preSort
            data.preSortPosition = positions
            return data
        })
    }

    removeSortColumnId(columnId: string): Promise<void> {
        return this.ref.runTransaction((data) => {
            delete data.preSort?.[columnId]
            delete data.preSortPosition?.[columnId]
            return data
        })
    }

    setSortColumnProp<K extends keyof SortColumn>(
        key: K,
        value: NonNullable<SortColumn[K]>,
        columnId: string
    ): Promise<void> {
        return this.ref.child('preSort').child(columnId).child(key).set(value)
    }

    setActiveFilterType(filterType: FilterType): Promise<void> {
        return this.ref.child('preFilter').child('type').set(filterType)
    }

    insertPreFilter(
        preFilterId: string,
        type: FilterType,
        filter: Filter | FormulaFilterBinding,
        priority: string
    ): Promise<void> {
        return this.ref.runTransaction((data) => {
            const newData: Partial<CollectionPage> = data ?? {}
            switch (type) {
                case 'custom':
                    {
                        const preFilters = {
                            ...(newData?.preFilter?.columnFilter ?? {}),
                        }
                        const { binding, condition } = filter as Filter
                        preFilters[preFilterId] = {
                            binding,
                            priority,
                            condition,
                        }

                        data.preFilter = {
                            ...data.preFilter,
                            type,
                            columnFilter: preFilters,
                        }
                    }
                    break
                case 'formula':
                    {
                        const preFilters = {
                            ...(newData?.preFilter?.formulaFilter ?? {}),
                        }
                        const { formulaBinding, condition } =
                            filter as FormulaFilterBinding
                        preFilters[preFilterId] = {
                            formulaBinding,
                            priority,
                            condition,
                        }
                        data.preFilter = {
                            ...data.preFilter,
                            type,
                            formulaFilter: preFilters,
                        }
                    }
                    break
                default:
                    break
            }

            return data
        })
    }

    addColumnFilter(
        columnFilterKey: string,
        bindingKey: string,
        value: ColumnBinding
    ): Promise<void> {
        return this.ref
            .child('preFilter')
            .child('columnFilter')
            .child(columnFilterKey)
            .child('binding')
            .child(bindingKey)
            .set(value)
    }

    changeColumnFilterClause(
        columnFilterId: string,
        clause: FilterClause
    ): Promise<void> {
        return this.ref
            .child('preFilter')
            .child('columnFilter')
            .child(columnFilterId)
            .child('condition')
            .set(clause)
    }

    changeColumnFilterBindingClause(
        columnFilterId: string,
        preFilterId: string,
        clause: ColumnBindingClause
    ): Promise<void> {
        return this.ref
            .child('preFilter')
            .child('columnFilter')
            .child(columnFilterId)
            .child('binding')
            .child(preFilterId)
            .child('filterClause')
            .set(clause)
    }

    removeFilterGroup(preFilterId: string, type: FilterType): Promise<void> {
        return this.ref.runTransaction((data) => {
            switch (type) {
                case 'custom': {
                    delete data.preFilter.columnFilter?.[preFilterId]
                    return data
                }
                case 'formula':
                    delete data.preFilter?.formulaFilter?.[preFilterId]
                    return data
                default:
                    break
            }

            return data
        })
    }

    removePreFilter(
        preFilterId: string,
        type: FilterType,
        columnFilterId?: string
    ): Promise<void> {
        return this.ref.runTransaction((data) => {
            switch (type) {
                case 'custom': {
                    delete data.preFilter.columnFilter?.[columnFilterId]
                        .binding?.[preFilterId]
                    return data
                }
                case 'formula':
                    delete data.preFilter?.formulaFilter?.[preFilterId]
                    return data
                default:
                    break
            }

            return data
        })
    }

    removeAllFilter(): Promise<void> {
        return this.ref.runTransaction((data) => {
            delete data.preFilter
            return data
        })
    }

    searchBarRepository(): CollectionPageSearchBarRepository {
        const searchBarRef = this.ref.child('search').ref
        const searchBarId = `__${this.pageId}_searchBar`
        return new FirebaseCollectionPageSearchBarRepository(
            searchBarRef,
            this.objectSnapshot?.search,
            searchBarId
        )
    }

    itemActionRepository(
        itemActionId: string
    ): CollectionPageItemActionRepository {
        const itemActionRef = this.ref
            .child('itemActions')
            .child(itemActionId).ref
        return new FirebaseCollectionPageItemActionRepository(
            itemActionRef,
            this.objectSnapshot?.itemActions?.[itemActionId],
            itemActionId
        )
    }
}

class FirebaseCollectionPageSearchBarRepository
    extends FirebaseRTDObjectRepository<SearchConfig>
    implements CollectionPageSearchBarRepository {}

class FirebaseCollectionPageItemActionRepository
    extends FirebaseRTDObjectRepository<ItemAction>
    implements CollectionPageItemActionRepository {}
