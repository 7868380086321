//
//  BaseRepository.ts
//
//  Created by Peter Vu on 2022-05-06 17:01:13.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { BasePage } from '@appformula/app-descriptor'
import { BasePageRepository } from '@appformula/app-studio-core'
import { Nullable } from '@appformula/shared-foundation-x'
import { Observable } from 'rxjs'
import { FirebaseRTDObjectRepository } from '../ObjectRepository'

export class FirebaseBasePageRepository<P extends BasePage>
    extends FirebaseRTDObjectRepository<P>
    implements BasePageRepository<P>
{
    get pageId(): string {
        return this.objectId
    }

    get page(): Observable<P> {
        return this.object
    }
    get pageSnapshot(): Nullable<P> {
        return this.objectSnapshot
    }
}
