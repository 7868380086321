//
//  EditorUIStore.ts
//
//  Created by Peter Vu on 2022-05-06 17:02:31.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { PageType } from '@appformula/app-descriptor'
import { makeObservable, observable } from 'mobx'
import { PageStore } from '..'
import {
    InspectorCollectionPageSelection,
    InspectorItemSelection,
} from './designer/InspectorItemSelection'
import { PageSelection } from './designer/PageSelection'
import { LexoRankInsertionPosition } from './helpers/LexoRankContainer'

export type PreviewMode = 'phone' | 'tablet'
export type PreviewPlatform = 'android' | 'ios'

export type TableTemp = {
    id: string
    name: string
}
export interface ResultDragEnd {
    lexoRank: LexoRankInsertionPosition
    targetComponent: string
}
export class DesignPageStore {
    pageSelection?: Readonly<PageSelection>
    inspectorItemSelection?: Readonly<InspectorItemSelection>

    previewMode: PreviewMode = 'phone'
    previewPlatform: PreviewPlatform = 'ios'

    designInspectorWidth: number
    appStructureWidth: number
    customPageHeight: number
    isOpenCreateEditPage: boolean
    pageSelectedStore?: PageStore
    pageTypeSelected?: PageType
    isFrontPage?: boolean
    tableTemp?: TableTemp
    isEditPage: boolean
    currentStructurePaneTab: number
    yCoordinateRN: number
    resultDragEnd: ResultDragEnd
    isDroppedComponent: boolean

    constructor() {
        this.designInspectorWidth = 220
        this.customPageHeight = 400
        this.appStructureWidth = 220
        this.inspectorItemSelection = new InspectorCollectionPageSelection(
            'collectionPage'
        )
        this.isOpenCreateEditPage = false
        this.isEditPage = false
        this.currentStructurePaneTab = 0
        this.yCoordinateRN = 0
        this.isDroppedComponent = true

        makeObservable(this, {
            pageSelection: observable.struct,
            inspectorItemSelection: observable.struct,
            previewMode: observable,
            previewPlatform: observable,
            designInspectorWidth: observable,
            appStructureWidth: observable,
            customPageHeight: observable,
            isOpenCreateEditPage: observable,
            pageTypeSelected: observable,
            pageSelectedStore: observable,
            isFrontPage: observable,
            tableTemp: observable,
            isEditPage: observable,
            currentStructurePaneTab: observable,
            yCoordinateRN: observable,
            isDroppedComponent: observable,
            resultDragEnd: observable,
        })
    }
}
