//
//  Files.ts
//
//  Created by Peter Vu on 2022-04-14 10:54:11.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { Nullable } from '@appformula/shared-foundation-x'
import { BaseFormInput } from './Base'

export interface FilesFormInput extends BaseFormInput {
    excludedFileTypes: Record<FileType, boolean>
}

const FileTypes = ['docs', 'images', 'audio', 'video'] as const
export type FileType = typeof FileTypes[number]

export function fileTypeFromString(string: string): Nullable<FileType> {
    return FileTypes.find((validName) => validName === string)
}
