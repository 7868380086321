//
//  TypedDBReference.ts
//
//  Created by Peter Vu on 2022-05-06 17:01:09.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

export class TypedDBReference<T> {
    readonly ref: firebase.default.database.Reference

    constructor(ref: firebase.default.database.Reference) {
        this.ref = ref
    }

    child<K extends Exclude<keyof T, number | symbol>>(key: K): TypedDBReference<NonNullable<T[K]>> {
        return new TypedDBReference<NonNullable<T[K]>>(this.ref.child(key))
    }

    set(value: T): Promise<void> {
        return this.ref.set(value)
    }

    async get(): Promise<T> {
        const snapshot = await this.ref.get()
        return snapshot.val() as unknown as T
    }

    update(value: Partial<T>): Promise<void> {
        return this.ref.update(value)
    }

    runTransaction(transactionUpdate: (data: Partial<T>) => Partial<T>): Promise<void> {
        return this.ref.transaction((transaction) => {
            return transactionUpdate(transaction)
        })
    }

    remove(): Promise<void> {
        return this.ref.remove()
    }
}
