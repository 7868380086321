//
//  Button.ts
//
//  Created by Peter Vu on 2022-05-06 17:04:02.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import {
    Binding,
    Button,
    ButtonStyle,
    ConstantBinding,
} from '@appformula/app-descriptor'
import { Nullable } from '@appformula/shared-foundation-x'
import { computed, makeObservable } from 'mobx'
import { ContentExportableComponentStore } from '.'
import { ControlComponentStore } from './Base'
import { PageComponentRepository } from './Base.repo'

export type ButtonRepository = PageComponentRepository<Button>

export class ButtonStore
    extends ControlComponentStore<Button>
    implements ContentExportableComponentStore
{
    get title(): Binding<string> {
        return this.object?.title ?? ConstantBinding.string('')
    }

    get icon(): Nullable<string> {
        return this.object?.icon
    }

    get color(): Nullable<Binding<string>> {
        return this.object?.color
    }

    get style(): ButtonStyle {
        return this.object?.style ?? 'plain'
    }

    constructor(repository: ButtonRepository) {
        super(repository)
        makeObservable(this, {
            title: computed.struct,
            icon: computed,
            color: computed.struct,
            style: computed,
        })
    }
    get exportPropNames(): string[] {
        return ['title', 'color']
    }
    exportedData(props: keyof Button): string[] {
        return [this.componentId, props]
    }
}
