//
//  Image.ts
//
//  Created by Peter Vu on 2022-05-06 17:04:18.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { Binding, Image } from '@appformula/app-descriptor'
import { Nullable } from '@appformula/shared-foundation-x'
import { computed, makeObservable } from 'mobx'
import { ContentExportableComponentStore } from '.'
import { BasePageComponentStore } from './Base'
import { PageComponentRepository } from './Base.repo'

export type ImageRepository = PageComponentRepository<Image>

export class ImageStore
    extends BasePageComponentStore<Image, ImageRepository>
    implements ContentExportableComponentStore
{
    get url(): Nullable<Binding<string>> {
        return this.object?.url
    }

    constructor(repository: ImageRepository) {
        super(repository)
        makeObservable(this, {
            url: computed.struct,
        })
    }
    get exportPropNames(): string[] {
        return ['url']
    }
    exportedData(props: keyof Image): string[] {
        return [this.componentId, props]
    }
}
