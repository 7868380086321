import { CustomPage, PageComponent, Binding, ComponentType } from '@appformula/app-descriptor'
import { Nullable } from '@appformula/shared-foundation-x'
import { mapValues } from 'lodash'
import { makeObservable, computed } from 'mobx'
import { ComponentContainerPageStore } from '../ComponentContainer'
import { BaseCustomPageRepository } from './Base.repo'
import {
    AudioRepository,
    AudioStore,
    BarcodeRepository,
    BarcodeStore,
    BooleanInputRepository,
    BooleanInputStore,
    ButtonRepository,
    ButtonStore,
    CarouselRepository,
    CarouselStore,
    ChartRepository,
    ChartStore,
    ComponentStore,
    DateRangeInputRepository,
    DateRangeInputStore,
    DateTimeInputRepository,
    DateTimeInputStore,
    DividerRepository,
    DividerStore,
    FilePreviewRepository,
    FilePreviewStore,
    InlineCollectionRepository,
    InlineCollectionStore,
    MapRepository,
    MapStore,
    MarkdownRepository,
    MarkdownStore,
    NumberScaleInputRepository,
    NumberScaleInputStore,
    NumberSliderInputRepository,
    NumberSliderInputStore,
    OptionsInputRepository,
    OptionsInputStore,
    PeopleInputRepository,
    PeopleInputStore,
    ProgressBarRepository,
    ProgressBarStore,
    RepeaterRepository,
    RepeaterStore,
    TextComponentRepository,
    TextComponentStore,
    TextInputRepository,
    TextInputStore,
    VideoRepository,
    VideoStore,
    WebLinkRepository,
    WebLinkStore,
} from './components'
import { ImageStore, ImageRepository } from './components/Image'

export class BaseCustomPageStore<
    P extends CustomPage,
    R extends BaseCustomPageRepository<P>
> extends ComponentContainerPageStore<PageComponent, P, R> {
    get coverImageUrl(): Nullable<Binding<string>> {
        return this.object?.coverImageUrl
    }

    get avatarUrl(): Nullable<Binding<string>> {
        return this.object?.avatarUrl
    }

    get componentTypes(): Record<string, ComponentType> {
        return mapValues(this.object?.components, (e) => e.componentType)
    }

    private componentStoreCache: Map<string, ComponentStore> = new Map()

    componentStore(componentId: string): Nullable<ComponentStore> {
        const componentType = this.componentTypes[componentId]
        if (componentType) {
            const cachedStore = this.componentStoreCache.get(componentId)
            if (cachedStore) {
                return cachedStore
            } else {
                const newComponentStore = this.makeComponentStore(componentId, componentType)
                this.componentStoreCache.set(componentId, newComponentStore)
                return newComponentStore
            }
        } else {
            return undefined
        }
    }

    constructor(repository: R) {
        super(repository)
        makeObservable(this, {
            coverImageUrl: computed.struct,
            avatarUrl: computed.struct,
            componentTypes: computed.struct,
        })
    }

    private makeComponentStore(componentId: string, componentType: ComponentType): ComponentStore {
        const componentRepository = this.repository.componentRepository(componentId, componentType)
        switch (componentType) {
            case 'audio':
                return new AudioStore(componentRepository as unknown as AudioRepository)
            case 'button':
                return new ButtonStore(componentRepository as unknown as ButtonRepository)
            case 'carousel':
                return new CarouselStore(componentRepository as unknown as CarouselRepository)
            case 'chart':
                return new ChartStore(componentRepository as unknown as ChartRepository)
            case 'filePreview':
                return new FilePreviewStore(componentRepository as unknown as FilePreviewRepository)
            case 'image':
                return new ImageStore(componentRepository as unknown as ImageRepository)
            case 'inlineCollection':
                return new InlineCollectionStore(componentRepository as unknown as InlineCollectionRepository)
            case 'map':
                return new MapStore(componentRepository as unknown as MapRepository)
            case 'markdown':
                return new MarkdownStore(componentRepository as unknown as MarkdownRepository)
            case 'repeater':
                return new RepeaterStore(componentRepository as unknown as RepeaterRepository)
            case 'text':
                return new TextComponentStore(componentRepository as unknown as TextComponentRepository)
            case 'video':
                return new VideoStore(componentRepository as unknown as VideoRepository)
            case 'webLink':
                return new WebLinkStore(componentRepository as unknown as WebLinkRepository)
            case 'barcode':
                return new BarcodeStore(componentRepository as unknown as BarcodeRepository)
            case 'booleanInput':
                return new BooleanInputStore(componentRepository as unknown as BooleanInputRepository)
            case 'dateRangeInput':
                return new DateRangeInputStore(componentRepository as unknown as DateRangeInputRepository)
            case 'dateTimeInput':
                return new DateTimeInputStore(componentRepository as unknown as DateTimeInputRepository)
            case 'divider':
                return new DividerStore(componentRepository as unknown as DividerRepository)
            case 'numberScaleInput':
                return new NumberScaleInputStore(componentRepository as unknown as NumberScaleInputRepository)
            case 'numberSliderInput':
                return new NumberSliderInputStore(componentRepository as unknown as NumberSliderInputRepository)
            case 'optionsInput':
                return new OptionsInputStore(componentRepository as unknown as OptionsInputRepository)
            case 'peopleInput':
                return new PeopleInputStore(componentRepository as unknown as PeopleInputRepository)
            case 'progressBar':
                return new ProgressBarStore(componentRepository as unknown as ProgressBarRepository)
            case 'textInput':
                return new TextInputStore(componentRepository as unknown as TextInputRepository)
            default:
                throw Error('')
        }
    }
}
