//
//  Markdown.ts
//
//  Created by Peter Vu on 2022-05-06 17:04:31.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { Binding, Markdown } from '@appformula/app-descriptor'
import { Nullable } from '@appformula/shared-foundation-x'
import { computed, makeObservable } from 'mobx'
import { ContentExportableComponentStore } from '.'
import { BasePageComponentStore } from './Base'
import { PageComponentRepository } from './Base.repo'

export type MarkdownRepository = PageComponentRepository<Markdown>

export class MarkdownStore
    extends BasePageComponentStore<Markdown, MarkdownRepository>
    implements ContentExportableComponentStore
{
    get text(): Nullable<Binding<string>> {
        return this.object?.text
    }

    constructor(repository: MarkdownRepository) {
        super(repository)
        makeObservable(this, {
            text: computed.struct,
        })
    }
    get exportPropNames(): string[] {
        return ['text']
    }
    exportedData(props: keyof Markdown): string[] {
        return [this.componentId, props]
    }
}
