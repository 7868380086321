//
//  BarcodeScanner.ts
//
//  Created by Peter Vu on 2022-05-06 17:02:46.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { BarcodeScannerFormInput, BarcodeType, barcodeTypeFromString } from '@appformula/app-descriptor'
import { computed, makeObservable } from 'mobx'
import { BaseFormInputStore, BaseFormInputRepository } from './Base'
import { compact } from 'lodash'

export interface BarcodeScannerFormInputRepository extends BaseFormInputRepository<BarcodeScannerFormInput> {
    addExcludeDetectedCodeType(codeType: BarcodeType): Promise<void>
    removeExcludeDetectedCodeType(codeType: BarcodeType): Promise<void>
}

export class BarcodeScannerFormInputStore extends BaseFormInputStore<
    BarcodeScannerFormInput,
    BarcodeScannerFormInputRepository
> {
    get excludeDetectedCodeTypes(): BarcodeType[] {
        const allPossibleValues = Object.entries(this.object?.excludeDetectedCodeTypes ?? {})
            .filter((e) => e[1])
            .map((e) => barcodeTypeFromString(e[0]))
        return compact(allPossibleValues)
    }

    constructor(repository: BarcodeScannerFormInputRepository) {
        super(repository)

        makeObservable(this, {
            excludeDetectedCodeTypes: computed.struct,
        })
    }

    addExcludeDetectedCodeType(codeType: BarcodeType): Promise<void> {
        return this.repository.addExcludeDetectedCodeType(codeType)
    }

    removeExcludeDetectedCodeType(codeType: BarcodeType): Promise<void> {
        return this.repository.removeExcludeDetectedCodeType(codeType)
    }
}
