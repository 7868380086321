import { Component } from '@appformula/app-descriptor'
import { PageComponentRepository } from '@appformula/app-studio-core'
import { Nullable } from '@appformula/shared-foundation-x'
import { Observable } from 'rxjs'
import { FirebaseRTDObjectRepository } from '../../../ObjectRepository'

export class FirebasePageComponentRepository<C extends Component>
    extends FirebaseRTDObjectRepository<C>
    implements PageComponentRepository<C>
{
    get componentId(): string {
        return this.objectId
    }

    get componentSnapshot(): Nullable<C> {
        return this.objectSnapshot
    }

    get component(): Observable<C> {
        return this.object
    }

    async update<K extends keyof C>(
        key: K,
        value: Partial<C[K]>
    ): Promise<void> {
        // @ts-ignore
        await this.ref.child(key).set(value)
    }
}
