//
//  Photos.ts
//
//  Created by Peter Vu on 2022-05-06 17:03:20.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { PhotosFormInput, PhotoSource, photoSourceFromString } from '@appformula/app-descriptor'
import { computed, makeObservable } from 'mobx'
import { BaseFormInputStore, BaseFormInputRepository } from './Base'
import * as _ from 'lodash'

export interface PhotosFormInputRepository extends BaseFormInputRepository<PhotosFormInput> {
    removeExcludeSource(source: PhotoSource): Promise<void>
    addExcludeSource(source: PhotoSource): Promise<void>
}

export class PhotosFormInputStore extends BaseFormInputStore<PhotosFormInput, PhotosFormInputRepository> {
    get excludedSources(): PhotoSource[] {
        const allPossibleSources = Object.entries(this.object?.excludedSources ?? {})
            .filter((e) => e[1])
            .map((e) => photoSourceFromString(e[0]))
        return _.compact(allPossibleSources)
    }

    constructor(repository: PhotosFormInputRepository) {
        super(repository)
        makeObservable(this, {
            excludedSources: computed.struct,
        })
    }

    addExclude(source: PhotoSource): Promise<void> {
        console.log(source)
        return this.repository.addExcludeSource(source)
    }

    removeExclude(source: PhotoSource): Promise<void> {
        return this.repository.removeExcludeSource(source)
    }
}
