//
//  AppDescriptorRepository.ts
//
//  Created by Peter Vu on 2022-05-06 17:01:22.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import {
    AppDescriptorRepository,
    DefaultPageType,
    PageRepository,
} from '@appformula/app-studio-core'
import {
    AppDescriptor,
    AuthenticationPage,
    CollectionPage,
    CustomPage,
    DetailsPage,
    FormPage,
    Page,
    PageType,
} from '@appformula/app-descriptor'
import { Observable } from 'rxjs'
import { FirebaseRTDObjectRepository } from './ObjectRepository'
import {
    FirebaseCollectionPageRepository,
    FirebaseCustomPageRepository,
    FirebaseFormPageRepository,
} from './pages'
import { Nullable } from '@appformula/shared-foundation-x'

export class FirebaseAppDescriptorRepository
    extends FirebaseRTDObjectRepository<AppDescriptor>
    implements AppDescriptorRepository
{
    get appDescriptorSnapshot(): Nullable<AppDescriptor> {
        return this.objectSnapshot
    }

    get appDescriptor(): Observable<AppDescriptor> {
        return this.object
    }

    constructor(
        dbRef: firebase.default.database.Reference,
        initialObject: Nullable<AppDescriptor>,
        appId: string
    ) {
        const appDescriptorObjectID = `AppDescriptor_${appId}`
        super(dbRef, initialObject, appDescriptorObjectID)
    }
    defaultPageRepositoryOf(
        tableId: string,
        pageType: DefaultPageType
    ): PageRepository {
        switch (pageType) {
            case 'details':
                return new FirebaseCustomPageRepository(
                    this.ref
                        .child('defaultPages')
                        .child(tableId)
                        .child('detailsPage').ref,
                    this.objectSnapshot?.defaultPages?.[tableId]
                        ?.detailsPage as unknown as DetailsPage,
                    'detailsPage'
                )
            case 'form':
                return new FirebaseFormPageRepository(
                    this.ref
                        .child('defaultPages')
                        .child(tableId)
                        .child('formPage').ref,
                    this.objectSnapshot?.defaultPages?.[tableId]
                        ?.formPage as unknown as FormPage,
                    'formPage'
                )
            default:
                break
        }
    }

    pageRepositoryOf(pageId: string, pageType: PageType): PageRepository {
        switch (pageType) {
            case 'collection':
                return new FirebaseCollectionPageRepository(
                    this.ref.child('pages').child(pageId).ref,
                    this.objectSnapshot?.pages?.[
                        pageId
                    ] as unknown as CollectionPage,
                    pageId
                )
            case 'custom':
                return new FirebaseCustomPageRepository(
                    this.ref.child('pages').child(pageId).ref,
                    this.objectSnapshot?.pages?.[
                        pageId
                    ] as unknown as CustomPage,
                    pageId
                )
            case 'form':
                return new FirebaseFormPageRepository(
                    this.ref.child('pages').child(pageId).ref,
                    this.objectSnapshot?.pages?.[pageId] as unknown as FormPage,
                    pageId
                )
            case 'details':
                return new FirebaseCustomPageRepository(
                    this.ref.child('pages').child(pageId).ref,
                    this.objectSnapshot?.pages?.[
                        pageId
                    ] as unknown as CustomPage,
                    pageId
                )
        }
    }

    insertPage(
        pageId: string,
        page: Page,
        rank: string,
        isFrontPage: boolean
    ): Promise<void> {
        return this.ref.runTransaction((data) => {
            const newData: Partial<AppDescriptor> = data ?? {}
            const pages = { ...(newData.pages ?? {}) }
            pages[pageId] = page
            newData.pages = pages

            const positions = { ...(newData.pagesPosition ?? {}) }
            positions[pageId] = rank
            newData.pagesPosition = positions

            if (isFrontPage) {
                const frontPageIds = { ...newData.frontPageIds }
                frontPageIds[pageId] = true
                newData.frontPageIds = frontPageIds
            }
            return newData
        })
    }

    removePageId(pageId: string): Promise<void> {
        return this.ref.runTransaction((data) => {
            delete data.pages?.[pageId]
            delete data.pagesPosition?.[pageId]
            delete data.frontPageIds?.[pageId]
            return data
        })
    }

    setPageRank(pageId: string, rank: string): Promise<void> {
        return this.ref.child('pagesPosition').child(pageId).set(rank)
    }

    removePageFromFrontPages(pageId: string): Promise<void> {
        return this.ref.child('frontPageIds').child(pageId).remove()
    }

    addPageToFrontPages(pageId: string): Promise<void> {
        return this.ref.child('frontPageIds').child(pageId).set(true)
    }

    setAuthenticationPageProps<K extends keyof AuthenticationPage>(
        key: K,
        value: NonNullable<AuthenticationPage[K]>
    ): Promise<void> {
        return this.ref.child('authenticationPage').child(key).set(value)
    }
}
