//
//  Divider.ts
//
//  Created by Peter Vu on 2022-05-06 17:04:14.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.

import { Divider } from '@appformula/app-descriptor'
import { BasePageComponentStore } from './Base'
import { PageComponentRepository } from './Base.repo'

export type DividerRepository = PageComponentRepository<Divider>
export class DividerStore extends BasePageComponentStore<Divider, DividerRepository> {}
