import { Observable } from 'rxjs'
import firebase from 'firebase'
import { fromDocRef } from './fromRef'

type DocumentData = firebase.firestore.DocumentData
type DocumentReference<T> = firebase.firestore.DocumentReference<T>
type DocumentSnapshot<T> = firebase.firestore.DocumentSnapshot<T>
type CollectionReference<T> = firebase.firestore.CollectionReference<T>
type QuerySnapshot<T> = firebase.firestore.QuerySnapshot<T>

export function docData<T = DocumentData>(ref: DocumentReference<T>): Observable<T> {
    return new Observable<T>((observer) => {
        const cancellationToken = ref.onSnapshot({
            next: (snapshot) => {
                observer.next(snapshot.data())
            },
        })

        return function tearDown() {
            cancellationToken()
        }
    })
}

export function collectionSnapshot<T = DocumentData>(ref: CollectionReference<T>): Observable<QuerySnapshot<T>> {
    return new Observable<QuerySnapshot<T>>((observer) => {
        const cancellationToken = ref.onSnapshot({
            next: (querySnapshot) => {
                observer.next(querySnapshot)
            },
        })

        return function tearDown() {
            cancellationToken()
        }
    })
}

export function doc<T = DocumentData>(ref: DocumentReference<T>): Observable<DocumentSnapshot<T>> {
    return fromDocRef(ref, { includeMetadataChanges: true })
}
