//
//  BooleanInput.ts
//
//  Created by Peter Vu on 2022-05-06 17:04:00.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import {
    Binding,
    BooleanInput,
    BooleanInputStyle,
} from '@appformula/app-descriptor'
import { Nullable } from '@appformula/shared-foundation-x'
import { computed, makeObservable } from 'mobx'
import { ContentExportableComponentStore } from '.'
import { ControlComponentStore } from './Base'
import { PageComponentRepository } from './Base.repo'

export type BooleanInputRepository = PageComponentRepository<BooleanInput>

export class BooleanInputStore
    extends ControlComponentStore<BooleanInput>
    implements ContentExportableComponentStore
{
    get title(): Nullable<Binding<string>> {
        return this.object?.title
    }

    get initialValue(): Nullable<Binding<boolean>> {
        return this.object?.initialValue
    }

    get style(): BooleanInputStyle {
        return this.object?.style ?? 'switch'
    }

    constructor(repository: BooleanInputRepository) {
        super(repository)
        makeObservable(this, {
            title: computed.struct,
            initialValue: computed.struct,
            style: computed,
        })
    }
    exportedData(props: keyof BooleanInput): string[] {
        return [this.componentId, props]
    }
    get exportPropNames(): string[] {
        return ['title', 'initialValue']
    }
}
