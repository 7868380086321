//
//  Chart.ts
//
//  Created by Peter Vu on 2022-05-06 17:04:07.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import {
    AxisBaseChart,
    Chart,
    ChartType,
    PieChart,
} from '@appformula/app-descriptor'
import { Nullable } from '@appformula/shared-foundation-x'
import { computed, makeObservable } from 'mobx'
import { Md5 } from 'ts-md5'
import { CollectionComponentRepository, CollectionComponentStore } from './Base'
import * as _ from 'lodash'
import { LexoRank } from 'lexorank'

export interface ChartRepository extends CollectionComponentRepository<Chart> {
    insertColorId(colorId: string, color: string, rank: string): Promise<void>
    removeColorId(colorId: string): Promise<void>
}

export class ChartStore extends CollectionComponentStore<
    Chart,
    ChartRepository
> {
    get chartType(): ChartType {
        return this.object?.chartType ?? 'bar'
    }

    get colors(): string[] {
        return Object.entries(this.object?.colors ?? {})
            .sort((lhs, rhs) => {
                const lhsRank = this.object?.colorsPosition?.[lhs[0]] ?? ''
                const rhsRank = this.object?.colorsPosition?.[rhs[0]] ?? ''
                return lhsRank.localeCompare(rhsRank)
            })
            .map((e) => e[1])
    }

    get pieChartConfig(): Nullable<PieChart> {
        return this.object?.pieChart
    }

    get axisChartConfig(): Nullable<AxisBaseChart> {
        return this.object?.axisChart
    }

    constructor(repository: ChartRepository) {
        super(repository)
        makeObservable(this, {
            chartType: computed,
            colors: computed,
            pieChartConfig: computed.struct,
            axisChartConfig: computed.struct,
        })
    }

    addColor(color: string): Promise<void> {
        const newColorRank =
            this.lastColorRank() ?? LexoRank.middle().toString()
        return this.repository.insertColorId(
            Md5.hashStr(color),
            color,
            newColorRank
        )
    }

    removeColor(color: string): Promise<void> {
        return this.repository.removeColorId(Md5.hashStr(color))
    }

    private lastColorRank(): Nullable<string> {
        const lastColor = _.last(this.colors)
        if (lastColor) {
            const lastColorId = Md5.hashStr(lastColor)
            return this.object?.colorsPosition?.[lastColorId]
        } else {
            return undefined
        }
    }

    setAxisChartProp<K extends keyof AxisBaseChart>(
        key: K,
        value: AxisBaseChart[K]
    ): Promise<void> {
        return this.repository.update('axisChart', {
            ...this.axisChartConfig,
            [key]: value,
        })
    }

    setPieChartProp<K extends keyof PieChart>(
        key: K,
        value: PieChart[K]
    ): Promise<void> {
        return this.repository.update('pieChart', {
            ...this.pieChartConfig,
            [key]: value,
        })
    }
}
