import { DefaultPageType } from '.'
import {
    CollectionPageItemActionStore,
    CollectionPageSearchBarStore,
    CollectionPageStore,
    ComponentStore,
    FormInputStore,
} from '../pages'

export type InspectorItemStore =
    | ComponentStore
    | FormInputStore
    | CollectionPageSearchBarStore
    | CollectionPageItemActionStore
    | CollectionPageStore

export type InspectorItemSelection =
    | InspectorPageComponentSelection
    | InspectorCollectionPageSelection
    | InspectorCollectionPageSearchBarSelection
    | InspectorCollectionPageItemActionSelection
    | InspectorAuthenticationPageItem
    | InspectorDefaultPageComponentSelection

export type AuthenticationComponent = 'branding' | 'behavior'
export class InspectorPageComponentSelection {
    readonly pageId: string
    readonly componentId: string

    constructor(pageId: string, componentId: string) {
        this.pageId = pageId
        this.componentId = componentId
    }
}

export class InspectorCollectionPageSelection {
    readonly collectionPageId: string

    constructor(collectionPageId: string) {
        this.collectionPageId = collectionPageId
    }
}

export class InspectorCollectionPageSearchBarSelection {
    readonly collectionPageId: string

    constructor(collectionPageId: string) {
        this.collectionPageId = collectionPageId
    }
}

export class InspectorCollectionPageItemActionSelection {
    readonly collectionPageId: string
    readonly actionId: string

    constructor(collectionPageId: string, actionId: string) {
        this.collectionPageId = collectionPageId
        this.actionId = actionId
    }
}

export class InspectorAuthenticationPageItem {
    readonly authPageComponentName: AuthenticationComponent

    constructor(componentName: AuthenticationComponent) {
        this.authPageComponentName = componentName
    }
}

export class InspectorDefaultPageComponentSelection {
    readonly pageType: DefaultPageType
    readonly tableId: string
    readonly componentId: string

    constructor(type: DefaultPageType, tableId: string, componentId: string) {
        this.pageType = type
        this.tableId = tableId
        this.componentId = componentId
    }
}
