//
//  Options.ts
//
//  Created by Peter Vu on 2022-05-06 17:03:12.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { Binding, FormulaValidator, OptionsFormInput, OptionsInputType } from '@appformula/app-descriptor'
import { Nullable } from '@appformula/shared-foundation-x'
import { computed, makeObservable } from 'mobx'
import { BaseFormInputStore, BaseFormInputRepository } from './Base'

export type OptionsFormInputRepository = BaseFormInputRepository<OptionsFormInput>

export class OptionsFormInputStore extends BaseFormInputStore<OptionsFormInput, OptionsFormInputRepository> {
    get defaultValue(): Nullable<Binding<string[]>> {
        return this.object?.defaultValue
    }

    get optionsInputType(): OptionsInputType {
        return this.object?.optionsInputType ?? 'inline'
    }

    get formulaValidator(): Nullable<FormulaValidator> {
        return this.object?.formulaValidator
    }

    constructor(repository: OptionsFormInputRepository) {
        super(repository)
        makeObservable(this, {
            defaultValue: computed,
            optionsInputType: computed,
            formulaValidator: computed,
        })
    }

    setValidatorProp<K extends keyof FormulaValidator>(key: K, value: FormulaValidator[K]): void {
        throw new Error('Method not implemented.')
    }
}
