//
//  Boolean.ts
//
//  Created by Peter Vu on 2022-05-06 17:02:51.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { Binding, BooleanFormInput } from '@appformula/app-descriptor'
import { Nullable } from '@appformula/shared-foundation-x'
import { computed, makeObservable } from 'mobx'
import { BaseFormInputStore, BaseFormInputRepository } from './Base'

export type BooleanFormInputRepository = BaseFormInputRepository<BooleanFormInput>

export class BooleanFormInputStore extends BaseFormInputStore<BooleanFormInput, BooleanFormInputRepository> {
    get defaultValue(): Nullable<Binding<boolean>> {
        return this.object?.defaultValue
    }

    constructor(repository: BooleanFormInputRepository) {
        super(repository)
        makeObservable(this, {
            defaultValue: computed.struct,
        })
    }
}
