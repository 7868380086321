//
//  Chart.ts
//
//  Created by Peter Vu on 2022-05-25 10:53:30.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { Chart } from '@appformula/app-descriptor'
import { ChartRepository } from '@appformula/app-studio-core'
import { FirebaseCollectionComponentRepository } from './CollectionComponent'

export class FirebaseChartRepository
    extends FirebaseCollectionComponentRepository<Chart>
    implements ChartRepository
{
    insertColorId(colorId: string, color: string, rank: string): Promise<void> {
        return this.ref.runTransaction((data) => {
            const colors = data.colors ?? {}
            colors[colorId] = color

            const positions = data.colorsPosition ?? {}
            positions[colorId] = rank
            return data
        })
    }

    removeColorId(colorId: string): Promise<void> {
        return this.ref.runTransaction((data) => {
            delete data.colors?.[colorId]
            delete data.colorsPosition?.[colorId]
            return data
        })
    }
}
