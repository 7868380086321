//
//  Location.ts
//
//  Created by Peter Vu on 2022-05-06 17:03:01.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { LocationFormat, LocationFormInput } from '@appformula/app-descriptor'
import { computed, makeObservable } from 'mobx'
import { BaseFormInputStore, BaseFormInputRepository } from './Base'

export type LocationFormInputRepository = BaseFormInputRepository<LocationFormInput>

export class LocationFormInputStore extends BaseFormInputStore<LocationFormInput, LocationFormInputRepository> {
    get format(): LocationFormat {
        return this.object?.format ?? 'address'
    }

    constructor(repository: LocationFormInputRepository) {
        super(repository)
        makeObservable(this, {
            format: computed,
        })
    }
}
