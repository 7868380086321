//
//  Carousel.ts
//
//  Created by Peter Vu on 2022-05-06 17:04:04.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { Binding, Carousel, ConstantBinding } from '@appformula/app-descriptor'
import { computed, makeObservable } from 'mobx'
import { BasePageComponentStore } from './Base'
import { PageComponentRepository } from './Base.repo'

export type CarouselRepository = PageComponentRepository<Carousel>

export class CarouselStore extends BasePageComponentStore<Carousel, PageComponentRepository<Carousel>> {
    get urls(): Binding<string[]> {
        return this.object?.urls ?? ConstantBinding.stringArray([])
    }

    constructor(repository: CarouselRepository) {
        super(repository)
        makeObservable(this, {
            urls: computed.struct,
        })
    }
}
