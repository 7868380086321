//
//  PeopleLookup.ts
//
//  Created by Peter Vu on 2022-05-06 17:03:15.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { PeopleLookupFormInput } from '@appformula/app-descriptor'
import { computed, makeObservable } from 'mobx'
import { BaseFormInputStore, BaseFormInputRepository } from './Base'

export type PeopleLookupFormInputRepository = BaseFormInputRepository<PeopleLookupFormInput>

export class PeopleLookupFormInputStore extends BaseFormInputStore<
    PeopleLookupFormInput,
    PeopleLookupFormInputRepository
> {
    get allowsMultipleSelection(): boolean {
        return this.object?.allowsMultipleSelection ?? false
    }

    get allowsMentioningOutsider(): boolean {
        return this.object?.allowsMentioningOutsider ?? false
    }

    constructor(repository: PeopleLookupFormInputRepository) {
        super(repository)
        makeObservable(this, {
            allowsMultipleSelection: computed,
            allowsMentioningOutsider: computed,
        })
    }
}
