//
//  TableRepository.ts
//
//  Created by Peter Vu on 2022-05-25 10:54:07.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { FieldTableType, Table } from '@appformula/app-descriptor'
import { TableRepository } from '@appformula/app-studio-core'
import { Nullable } from '@appformula/shared-foundation-x'
import { Observable } from 'rxjs'
import { FirestoreDocObjectRepository } from '../ObjectRepository'

export class FirebaseTableRepository
    extends FirestoreDocObjectRepository<Table>
    implements TableRepository
{
    get tableSnapshot(): Nullable<Table> {
        return this.objectSnapshot
    }

    get table(): Observable<Table> {
        return this.object
    }

    get tableId(): string {
        return this.objectId
    }

    set<K extends keyof Table>(key: K, value: Table[K]): Promise<void> {
        throw new Error('Method not implemented.')
    }

    setColumnType(columnName: string, newType: FieldTableType): Promise<void> {
        throw new Error('Method not implemented.')
    }
}
