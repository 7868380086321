//
//  FormPage.ts
//
//  Created by Peter Vu on 2022-05-06 17:03:44.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { FormInput, FormInputType, FormPage } from '@appformula/app-descriptor'
import { Nullable } from '@appformula/shared-foundation-x'
import { mapValues } from 'lodash'
import { computed, makeObservable } from 'mobx'
import { ComponentContainerPageStore } from '../ComponentContainer'
import {
    BarcodeScannerFormInputRepository,
    BarcodeScannerFormInputStore,
    BooleanFormInputRepository,
    BooleanFormInputStore,
    DateTimeFormInputRepository,
    DateTimeFormInputStore,
    FilesFormInputRepository,
    FilesFormInputStore,
    FormInputStore,
    LocationFormInputRepository,
    LocationFormInputStore,
    LookupFormInputRepository,
    LookupFormInputStore,
    MultiLineTextFormInputRepository,
    MultiLineTextFormInputStore,
    NumberFormInputRepository,
    NumberFormInputStore,
    OptionsFormInputRepository,
    OptionsFormInputStore,
    PeopleLookupFormInputRepository,
    PeopleLookupFormInputStore,
    PhotosFormInputRepository,
    PhotosFormInputStore,
    SignatureDrawingFormInputRepository,
    SignatureDrawingFormInputStore,
    SingleLineTextFormInputRepository,
    SingleLineTextFormInputStore,
} from './components'
import { FormPageRepository } from './FormPage.repo'

export class FormPageStore extends ComponentContainerPageStore<FormInput, FormPage, FormPageRepository> {
    get tableId(): string {
        return this.object?.tableId ?? ''
    }

    get componentTypes(): Record<string, FormInputType> {
        return mapValues(this.object?.components, (e) => e.inputType)
    }

    private componentStoreCache: Map<string, FormInputStore> = new Map()

    constructor(repository: FormPageRepository) {
        super(repository)
        makeObservable(this, {
            tableId: computed,
            componentTypes: computed.struct,
        })
    }

    componentStore(componentId: string): Nullable<FormInputStore> {
        const componentType = this.componentTypes[componentId]
        if (componentType) {
            const cachedStore = this.componentStoreCache.get(componentId)
            if (cachedStore) {
                return cachedStore
            } else {
                const newComponentStore = this.makeComponentStore(componentId, componentType)
                this.componentStoreCache.set(componentId, newComponentStore)
                return newComponentStore
            }
        } else {
            return undefined
        }
    }

    private makeComponentStore(componentId: string, componentType: FormInputType): FormInputStore {
        const componentRepository = this.repository.componentRepository(componentId, componentType)
        switch (componentType) {
            case 'options':
                return new OptionsFormInputStore(componentRepository as unknown as OptionsFormInputRepository)
            case 'number':
                return new NumberFormInputStore(componentRepository as unknown as NumberFormInputRepository)
            case 'boolean':
                return new BooleanFormInputStore(componentRepository as unknown as BooleanFormInputRepository)
            case 'dateTime':
                return new DateTimeFormInputStore(componentRepository as unknown as DateTimeFormInputRepository)
            case 'location':
                return new LocationFormInputStore(componentRepository as unknown as LocationFormInputRepository)
            case 'lookup':
                return new LookupFormInputStore(componentRepository as unknown as LookupFormInputRepository)
            case 'multiLineText':
                return new MultiLineTextFormInputStore(
                    componentRepository as unknown as MultiLineTextFormInputRepository
                )
            case 'singleLineText':
                return new SingleLineTextFormInputStore(
                    componentRepository as unknown as SingleLineTextFormInputRepository
                )
            case 'peopleLookup':
                return new PeopleLookupFormInputStore(componentRepository as unknown as PeopleLookupFormInputRepository)
            case 'barcodeScanner':
                return new BarcodeScannerFormInputStore(
                    componentRepository as unknown as BarcodeScannerFormInputRepository
                )
            case 'files':
                return new FilesFormInputStore(componentRepository as unknown as FilesFormInputRepository)
            case 'photos':
                return new PhotosFormInputStore(componentRepository as unknown as PhotosFormInputRepository)
            case 'signatureDrawing':
                return new SignatureDrawingFormInputStore(
                    componentRepository as unknown as SignatureDrawingFormInputRepository
                )
            default:
                throw Error('')
        }
    }
}
