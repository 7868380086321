//
//  Base.ts
//
//  Created by Peter Vu on 2022-05-06 17:03:58.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import {
    Action,
    Binding,
    CollectionComponent,
    Component,
    ComponentType,
    ConstantBinding,
    ControlComponent,
    DataSource,
    DataSourceFilter,
    Filter,
    FilterType,
    PageComponent,
    SortColumn,
    SortOrder,
} from '@appformula/app-descriptor'
import { Nullable } from '@appformula/shared-foundation-x'
import { computed, makeObservable } from 'mobx'
import { Md5 } from 'ts-md5'
import {
    LexoRankContainer,
    LexoRankInsertionPosition,
} from '../../../helpers/LexoRankContainer'
import { ObservableObjectStore } from '../../../ObservableObjectStore'
import { PageComponentRepository } from './Base.repo'
import * as _ from 'lodash'

export interface ContentExportableComponentStore {
    exportedData(props: keyof PageComponent): string[]
    get exportPropNames(): string[]
}

export class BasePageComponentStore<
    C extends Component,
    R extends PageComponentRepository<C>
> extends ObservableObjectStore<C> {
    protected readonly repository: R

    get componentId(): string {
        return this.repository.componentId
    }

    get isHidden(): Binding<boolean> {
        return this.object?.isHidden ?? ConstantBinding.boolean(false)
    }

    get referenceName(): Nullable<string> {
        return this.object?.referenceName
    }

    get componentType(): ComponentType {
        return this.object?.componentType ?? 'text'
    }

    constructor(repository: R) {
        super(repository.component, repository.componentSnapshot)
        this.repository = repository

        makeObservable(this, {
            isHidden: computed.struct,
            componentType: computed,
            referenceName: computed,
        })
    }

    changeReferenceName(name: string): Promise<void> {
        return this.repository.set('referenceName', name)
    }

    async set<K extends Exclude<keyof C, 'componentType'>>(
        key: K,
        value: C[K]
    ): Promise<void> {
        this.willSet(key, value)
        await this.repository.set(key, value)
        this.didSet(key, value)
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected willSet<K extends keyof C>(key: K, newValue: C[K]): void {
        return
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected didSet<K extends keyof C>(key: K, newValue: C[K]): void {
        return
    }
}

export class ControlComponentStore<C extends ControlComponent>
    extends BasePageComponentStore<C, PageComponentRepository<C>>
    implements ControlComponent
{
    get disabled(): Binding<boolean> {
        return this.object?.disabled ?? ConstantBinding.boolean(false)
    }

    get onTapAction(): Nullable<Action> {
        return this.object?.onTapAction
    }

    get valueChangedAction(): Nullable<Action> {
        return this.object?.valueChangedAction
    }

    constructor(repository: PageComponentRepository<C>) {
        super(repository)

        makeObservable(this, {
            disabled: computed,
            onTapAction: computed,
            valueChangedAction: computed.struct,
        })
    }

    setAction(action: Action): Promise<void> {
        return this.repository.set('onTapAction', action)
    }

    setOnChangeValueAction(action: Action): Promise<void> {
        return this.repository.set('valueChangedAction', action)
    }

    setSpecificationAction<T extends Action, K extends keyof T>(
        key: K,
        value: T[K]
    ): Promise<void> {
        const tapAction: T = this.object.onTapAction as T
        if (tapAction) {
            tapAction[key] = value
        }
        return this.repository.set('onTapAction', tapAction)
    }

    setSpecificationOnValueChangedAction<T extends Action, K extends keyof T>(
        key: K,
        value: T[K]
    ): Promise<void> {
        const valueChangedAction: T = this.object.valueChangedAction as T
        if (valueChangedAction) {
            valueChangedAction[key] = value
        }
        return this.repository.set('valueChangedAction', valueChangedAction)
    }

    setBaseActionProp<K extends keyof Action>(
        key: K,
        value: Action[K]
    ): Promise<void> {
        const tapAction = this.object.onTapAction
        if (tapAction) {
            tapAction[key] = value
        }

        return this.repository.set('onTapAction', tapAction)
    }

    setValueChangedActionProp<K extends keyof Action>(
        key: K,
        value: Action[K]
    ): Promise<void> {
        const valueChangedAction = this.object.valueChangedAction
        if (valueChangedAction) {
            valueChangedAction[key] = value
        }

        return this.repository.set('valueChangedAction', valueChangedAction)
    }
}

export interface CollectionComponentRepository<C extends CollectionComponent>
    extends PageComponentRepository<C> {
    insertSortColumn(
        columnId: string,
        columnName: string,
        sortOrder: SortOrder,
        rank: string
    ): Promise<void>

    setColumnPosition(columnId: string, rank: string): Promise<void>
    setColumnProp<K extends keyof SortColumn>(
        key: K,
        value: SortColumn[K],
        columnId: string
    ): Promise<void>

    removeSortColumnId(columnId: string): Promise<void>

    insertPreFilter(
        preFilterId: string,
        filter: Filter,
        priority: string
    ): Promise<void>
}

export class CollectionComponentStore<
    C extends CollectionComponent,
    R extends CollectionComponentRepository<C>
> extends BasePageComponentStore<C, R> {
    get dataSource(): Nullable<DataSource> {
        return this.object?.dataSource
    }

    get preFilter(): Nullable<DataSourceFilter> {
        return this.object?.preFilter
    }

    get sortColumns(): SortColumn[] {
        const positions = this.object?.sortColumnsPosition ?? {}

        return Object.entries(this.object?.sort ?? {})
            .sort((lhs, rhs) => {
                const lhsPosition = positions[lhs[0]] ?? ''
                const rhsPosition = positions[rhs[0]] ?? ''
                return lhsPosition.localeCompare(rhsPosition)
            })
            .map((e) => e[1])
    }

    private get allColumnIds(): string[] {
        return this.sortColumns.map((e) => Md5.hashStr(e.columnName))
    }

    constructor(repository: R) {
        super(repository)
        makeObservable(this, {
            dataSource: computed.struct,
            preFilter: computed,
            sortColumns: computed.struct,
        })
    }

    // MARK: - Sorting
    addSortColumn(column: SortColumn): Promise<void> {
        const lastColumn = this.sortColumns[this.sortColumns.length - 1]
        const columnId = Md5.hashStr(column.columnName)
        let columnRank: string
        const lexoRankContainer = new LexoRankContainer(
            this.allColumnIds,
            this.object?.sortColumnsPosition ?? {}
        )

        if (lastColumn) {
            const lastColumnId = Md5.hashStr(lastColumn.columnName)
            columnRank = lexoRankContainer.insertionRank('below', lastColumnId)
        } else {
            columnRank = lexoRankContainer.insertionRank('below', undefined)
        }

        return this.repository.insertSortColumn(
            columnId,
            column.columnName,
            column.order,
            columnRank.toString()
        )
    }

    removeSortColumn(columnName: string): Promise<void> {
        return this.repository.removeSortColumnId(Md5.hashStr(columnName))
    }

    setColumnOrder(columnName: string, order: SortOrder): Promise<void> {
        return this.repository.setColumnProp(
            'order',
            order,
            Md5.hashStr(columnName)
        )
    }

    insertColumn(
        column: SortColumn,
        position: LexoRankInsertionPosition,
        targetColumnName: Nullable<string>
    ): Promise<void> {
        const columnId = Md5.hashStr(column.columnName)
        const lexoRankContainer = new LexoRankContainer(
            this.allColumnIds,
            this.object?.sortColumnsPosition ?? {}
        )
        let newRank: string

        if (targetColumnName) {
            newRank = lexoRankContainer.insertionRank(
                position,
                Md5.hashStr(targetColumnName)
            )
        } else {
            newRank = lexoRankContainer.insertionRank(position, undefined)
        }

        return this.repository.insertSortColumn(
            columnId,
            column.columnName,
            column.order,
            newRank.toString()
        )
    }

    insertPreFilter(
        preFilterId: string,
        type: FilterType,
        filter: Filter
    ): Promise<void> {
        const preFilterIds: string[] = []
        const priorities: Record<string, string> = {}
        switch (type) {
            case 'custom':
                {
                    if (this.object.preFilter.columnFilter) {
                        Object.entries(
                            this.object.preFilter.columnFilter
                        ).forEach(([key, value]) => {
                            preFilterIds.push(key)
                            priorities[key] = value.priority
                        })
                    }
                }
                break
            case 'formula':
                {
                    if (this.object.preFilter.formulaFilter) {
                        Object.entries(
                            this.object.preFilter.formulaFilter
                        ).forEach(([key, value]) => {
                            preFilterIds.push(key)
                            priorities[key] = value.priority
                        })
                    }
                }
                break
            default:
                break
        }

        const lexoRankContainer = new LexoRankContainer(
            preFilterIds,
            priorities ?? {}
        )
        const rankForNewFilter = lexoRankContainer.insertionRank(
            'below',
            _.last(preFilterIds)
        )
        return this.repository.insertPreFilter(
            preFilterId,
            filter,
            rankForNewFilter
        )
    }
}
