//
//  Map.ts
//
//  Created by Peter Vu on 2022-05-06 17:04:29.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { Binding, Map } from '@appformula/app-descriptor'
import { Nullable } from '@appformula/shared-foundation-x'
import { computed, makeObservable } from 'mobx'
import { ContentExportableComponentStore } from '.'
import { CollectionComponentRepository, CollectionComponentStore } from './Base'

export type MapRepository = CollectionComponentRepository<Map>

export class MapStore
    extends CollectionComponentStore<Map, MapRepository>
    implements ContentExportableComponentStore
{
    get address(): Nullable<Binding<string>> {
        return this.object?.address
    }

    get pinColor(): Nullable<string> {
        return this.object?.pinColor
    }

    get pinIcon(): Nullable<'user'> {
        return this.object?.pinIcon
    }

    get calloutEnabled(): boolean {
        return this.object?.calloutEnabled ?? false
    }

    get calloutTitle(): Nullable<Binding<string>> {
        return this.object?.calloutTitle
    }

    get calloutSubtitle(): Nullable<Binding<string>> {
        return this.object?.calloutSubtitle
    }

    constructor(repository: MapRepository) {
        super(repository)
        makeObservable(this, {
            address: computed.struct,
            pinColor: computed,
            pinIcon: computed,
            calloutEnabled: computed.struct,
            calloutTitle: computed.struct,
            calloutSubtitle: computed.struct,
        })
    }
    get exportPropNames(): string[] {
        return ['address']
    }
    exportedData(props: keyof Map): string[] {
        return [this.componentId, props]
    }
}
