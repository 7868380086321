//
//  PeopleInput.ts
//
//  Created by Peter Vu on 2022-05-06 17:04:46.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import {
    Binding,
    ConstantBinding,
    PeopleInput,
} from '@appformula/app-descriptor'
import { Nullable } from '@appformula/shared-foundation-x'
import { computed, makeObservable } from 'mobx'
import { ContentExportableComponentStore } from '.'
import { ControlComponentStore } from './Base'
import { PageComponentRepository } from './Base.repo'

export type PeopleInputRepository = PageComponentRepository<PeopleInput>

export class PeopleInputStore
    extends ControlComponentStore<PeopleInput>
    implements ContentExportableComponentStore
{
    get title(): Nullable<Binding<string>> {
        return this.object?.title
    }

    get initialEmailAddresses(): Binding<string[]> {
        return (
            this.object?.initialEmailAddresses ??
            ConstantBinding.stringArray([])
        )
    }

    get allowsMultipleSelection(): boolean {
        return this.object?.allowsMultipleSelection ?? false
    }

    constructor(repository: PeopleInputRepository) {
        super(repository)
        makeObservable(this, {
            title: computed.struct,
            initialEmailAddresses: computed.struct,
            allowsMultipleSelection: computed,
        })
    }
    get exportPropNames(): string[] {
        return ['title']
    }
    exportedData(props: keyof PeopleInput): string[] {
        return [this.componentId, props]
    }
}
