//
//  SignatureDrawing.ts
//
//  Created by thaitd96 on 2022-06-28 17:03:25.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { SignatureDrawingFormInput } from '@appformula/app-descriptor'
import { makeObservable } from 'mobx'
import { BaseFormInputStore, BaseFormInputRepository } from './Base'

export type SignatureDrawingFormInputRepository = BaseFormInputRepository<SignatureDrawingFormInput>

export class SignatureDrawingFormInputStore extends BaseFormInputStore<
    SignatureDrawingFormInput,
    SignatureDrawingFormInputRepository
> {
    constructor(repository: SignatureDrawingFormInputRepository) {
        super(repository)
        makeObservable(this, {})
    }
}
