//
//  CustomPage.ts
//
//  Created by Peter Vu on 2022-05-25 10:53:20.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import {
    Barcode,
    BooleanInput,
    Button,
    Carousel,
    Chart,
    ComponentType,
    CustomPage,
    DateRangeInput,
    DateTimeInput,
    Divider,
    FilePreview,
    Image,
    InlineCollection,
    Map,
    Markdown,
    NumberScaleInput,
    NumberSliderInput,
    OptionsInput,
    PageComponent,
    PeopleInput,
    ProgressBar,
    Repeater,
    TextComponent,
    TextInput,
    Video,
    WebLink,
} from '@appformula/app-descriptor'
import {
    ComponentRepository,
    CustomPageRepository,
} from '@appformula/app-studio-core'
import { FirebaseBasePageRepository } from '../BaseRepository'
import { FirebasePageComponentRepository } from './components/Base'
import { Audio } from '@appformula/app-descriptor'
import { FirebaseCollectionComponentRepository } from './components/CollectionComponent'
import { FirebaseChartRepository } from './components/Chart'

export class FirebaseCustomPageRepository
    extends FirebaseBasePageRepository<CustomPage>
    implements CustomPageRepository
{
    componentRepository(
        componentId: string,
        componentType: ComponentType
    ): ComponentRepository {
        const componentRef = this.ref.child('components').child(componentId).ref
        const component = this.objectSnapshot?.components?.[componentId]

        switch (componentType) {
            case 'audio':
                return new FirebasePageComponentRepository<Audio>(
                    componentRef,
                    component as unknown as Audio,
                    componentId
                )
            case 'button':
                return new FirebasePageComponentRepository<Button>(
                    componentRef,
                    component as unknown as Button,
                    componentId
                )
            case 'carousel':
                return new FirebasePageComponentRepository<Carousel>(
                    componentRef,
                    component as unknown as Carousel,
                    componentId
                )
            case 'chart':
                return new FirebaseChartRepository(
                    componentRef,
                    component as unknown as Chart,
                    componentId
                )
            case 'filePreview':
                return new FirebasePageComponentRepository<FilePreview>(
                    componentRef,
                    component as unknown as FilePreview,
                    componentId
                )
            case 'image':
                return new FirebasePageComponentRepository<Image>(
                    componentRef,
                    component as unknown as Image,
                    componentId
                )
            case 'inlineCollection':
                return new FirebaseCollectionComponentRepository<InlineCollection>(
                    componentRef,
                    component as unknown as InlineCollection,
                    componentId
                )
            case 'map':
                return new FirebaseCollectionComponentRepository<Map>(
                    componentRef,
                    component as unknown as Map,
                    componentId
                )
            case 'markdown':
                return new FirebasePageComponentRepository<Markdown>(
                    componentRef,
                    component as unknown as Markdown,
                    componentId
                )
            case 'repeater':
                return new FirebasePageComponentRepository<Repeater>(
                    componentRef,
                    component as unknown as Repeater,
                    componentId
                )
            case 'text':
                return new FirebasePageComponentRepository<TextComponent>(
                    componentRef,
                    component as unknown as TextComponent,
                    componentId
                )
            case 'video':
                return new FirebasePageComponentRepository<Video>(
                    componentRef,
                    component as unknown as Video,
                    componentId
                )
            case 'webLink':
                return new FirebasePageComponentRepository<WebLink>(
                    componentRef,
                    component as unknown as WebLink,
                    componentId
                )
            case 'barcode':
                return new FirebasePageComponentRepository<Barcode>(
                    componentRef,
                    component as unknown as Barcode,
                    componentId
                )
            case 'booleanInput':
                return new FirebasePageComponentRepository<BooleanInput>(
                    componentRef,
                    component as unknown as BooleanInput,
                    componentId
                )
            case 'dateRangeInput':
                return new FirebasePageComponentRepository<DateRangeInput>(
                    componentRef,
                    component as unknown as DateRangeInput,
                    componentId
                )
            case 'dateTimeInput':
                return new FirebasePageComponentRepository<DateTimeInput>(
                    componentRef,
                    component as unknown as DateTimeInput,
                    componentId
                )
            case 'divider':
                return new FirebasePageComponentRepository<Divider>(
                    componentRef,
                    component as unknown as Divider,
                    componentId
                )
            case 'numberScaleInput':
                return new FirebasePageComponentRepository<NumberScaleInput>(
                    componentRef,
                    component as unknown as NumberScaleInput,
                    componentId
                )
            case 'numberSliderInput':
                return new FirebasePageComponentRepository<NumberSliderInput>(
                    componentRef,
                    component as unknown as NumberSliderInput,
                    componentId
                )
            case 'optionsInput':
                return new FirebasePageComponentRepository<OptionsInput>(
                    componentRef,
                    component as unknown as OptionsInput,
                    componentId
                )
            case 'peopleInput':
                return new FirebasePageComponentRepository<PeopleInput>(
                    componentRef,
                    component as unknown as PeopleInput,
                    componentId
                )
            case 'progressBar':
                return new FirebasePageComponentRepository<ProgressBar>(
                    componentRef,
                    component as unknown as ProgressBar,
                    componentId
                )
            case 'textInput':
                return new FirebasePageComponentRepository<TextInput>(
                    componentRef,
                    component as unknown as TextInput,
                    componentId
                )
            default:
                throw Error('')
        }
    }

    insertComponent(
        componentId: string,
        component: PageComponent,
        position: string
    ): Promise<void> {
        return this.ref.runTransaction((data) => {
            const componentsMap = data.components ?? {}
            componentsMap[componentId] = component
            data.components = componentsMap

            const componentsPosition = data.componentsPosition ?? {}
            componentsPosition[componentId] = position
            data.componentsPosition = componentsPosition
            return data
        })
    }

    setComponentPosition(componentId: string, position: string): Promise<void> {
        return this.ref
            .child('componentsPosition')
            .child(componentId)
            .set(position)
    }

    removeComponent(componentId: string): Promise<void> {
        return this.ref.runTransaction((data) => {
            delete data.componentsPosition?.[componentId]
            delete data.components?.[componentId]
            return data
        })
    }
}
