//
//  DataPageStore.ts
//
//  Created by Peter Vu on 2022-05-24 10:46:11.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { AppDataSourceDescriptor, Table } from '@appformula/app-descriptor'
import { Nullable } from '@appformula/shared-foundation-x'
import { action, computed, makeObservable } from 'mobx'
import { nanoid } from 'nanoid'
import { Observable } from 'rxjs'
import { TableRepository, TableStore } from './data/TableStore'
import { ObservableObjectStore } from './ObservableObjectStore'

export interface AppDataSourceRepository {
    get appDataSourceDescriptorSnapshot(): Nullable<AppDataSourceDescriptor>
    get appDataSourceDescriptor(): Observable<AppDataSourceDescriptor>

    addTable(tableId: string, table: Table): Promise<void>
    remove(tableId: string): Promise<void>

    tableRepository(tableId: string): TableRepository
}

export class AppDataSourceStore extends ObservableObjectStore<AppDataSourceDescriptor> {
    
    get tableIds(): string[] {
        return Object.keys(this.object?.tables ?? {})
    }

    private repository: AppDataSourceRepository
    private tableStoreCache: Map<string, TableStore> = new Map()

    constructor(repository: AppDataSourceRepository) {
        super(repository.appDataSourceDescriptor, repository.appDataSourceDescriptorSnapshot)
        this.repository = repository

        makeObservable(this, {
            tableIds: computed.struct,
            addTable: action,
        })
    }

    tableStore(tableId: string): TableStore {
        const cachedTableStore = this.tableStoreCache.get(tableId)
        if (cachedTableStore) {
            return cachedTableStore
        } else {
            const repository = this.repository.tableRepository(tableId)
            const newTableStore = new TableStore(repository)
            this.tableStoreCache.set(tableId, newTableStore)
            return newTableStore
        }
    }

    addTable(table: Table): Promise<void> {
        const newTableId = nanoid(6)
        return this.repository.addTable(newTableId, table)
    }

    remove(tableId: string): Promise<void> {
        return this.repository.remove(tableId)
    }
}
