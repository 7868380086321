//
//  Photos.ts
//
//  Created by Peter Vu on 2022-05-25 10:53:03.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { PhotosFormInput, PhotoSource } from '@appformula/app-descriptor'
import { PhotosFormInputRepository } from '@appformula/app-studio-core'
import { FirebaseFormInputRepository } from '../FormInputRepository'

export class FirebasePhotosFormInputRepository
    extends FirebaseFormInputRepository<PhotosFormInput>
    implements PhotosFormInputRepository
{
    removeExcludeSource(source: PhotoSource): Promise<void> {
        return this.ref.child('excludedSources').child(source).set(false)
    }

    addExcludeSource(source: PhotoSource): Promise<void> {
        return this.ref.child('excludedSources').child(source).set(true)
    }
}
