"use strict";

var _barcodes = require("./barcodes/");

var _barcodes2 = _interopRequireDefault(_barcodes);

var _merge = require("./help/merge.js");

var _merge2 = _interopRequireDefault(_merge);

var _linearizeEncodings = require("./help/linearizeEncodings.js");

var _linearizeEncodings2 = _interopRequireDefault(_linearizeEncodings);

var _fixOptions = require("./help/fixOptions.js");

var _fixOptions2 = _interopRequireDefault(_fixOptions);

var _getRenderProperties = require("./help/getRenderProperties.js");

var _getRenderProperties2 = _interopRequireDefault(_getRenderProperties);

var _optionsFromStrings = require("./help/optionsFromStrings.js");

var _optionsFromStrings2 = _interopRequireDefault(_optionsFromStrings);

var _ErrorHandler = require("./exceptions/ErrorHandler.js");

var _ErrorHandler2 = _interopRequireDefault(_ErrorHandler);

var _exceptions = require("./exceptions/exceptions.js");

var _defaults = require("./options/defaults.js");

var _defaults2 = _interopRequireDefault(_defaults);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// The protype of the object returned from the JsBarcode() call


// Help functions
var API = function API() {};

// The first call of the library API
// Will return an object with all barcodes calls and the data that is used
// by the renderers


// Default values


// Exceptions
// Import all the barcodes
var JsBarcode = function JsBarcode(element, text, options) {
  var api = new API();

  if (typeof element === "undefined") {
    throw Error("No element to render on was provided.");
  }

  // Variables that will be pased through the API calls
  api._renderProperties = (0, _getRenderProperties2.default)(element);
  api._encodings = [];
  api._options = _defaults2.default;
  api._errorHandler = new _ErrorHandler2.default(api);

  // If text is set, use the simple syntax (render the barcode directly)
  if (typeof text !== "undefined") {
    options = options || {};

    if (!options.format) {
      options.format = autoSelectBarcode();
    }

    api.options(options)[options.format](text, options).render();
  }

  return api;
};

// To make tests work TODO: remove
JsBarcode.getModule = function (name) {
  return _barcodes2.default[name];
};

// Register all barcodes
for (var name in _barcodes2.default) {
  if (_barcodes2.default.hasOwnProperty(name)) {
    // Security check if the propery is a prototype property
    registerBarcode(_barcodes2.default, name);
  }
}
function registerBarcode(barcodes, name) {
  API.prototype[name] = API.prototype[name.toUpperCase()] = API.prototype[name.toLowerCase()] = function (text, options) {
    var api = this;
    return api._errorHandler.wrapBarcodeCall(function () {
      // Ensure text is options.text
      options.text = typeof options.text === "undefined" ? undefined : "" + options.text;

      var newOptions = (0, _merge2.default)(api._options, options);
      newOptions = (0, _optionsFromStrings2.default)(newOptions);
      var Encoder = barcodes[name];
      var encoded = encode(text, Encoder, newOptions);
      api._encodings.push(encoded);

      return api;
    });
  };
}

// encode() handles the Encoder call and builds the binary string to be rendered
function encode(text, Encoder, options) {
  // Ensure that text is a string
  text = "" + text;

  var encoder = new Encoder(text, options);

  // If the input is not valid for the encoder, throw error.
  // If the valid callback option is set, call it instead of throwing error
  if (!encoder.valid()) {
    throw new _exceptions.InvalidInputException(encoder.constructor.name, text);
  }

  // Make a request for the binary data (and other infromation) that should be rendered
  var encoded = encoder.encode();

  // Encodings can be nestled like [[1-1, 1-2], 2, [3-1, 3-2]
  // Convert to [1-1, 1-2, 2, 3-1, 3-2]
  encoded = (0, _linearizeEncodings2.default)(encoded);

  // Merge
  for (var i = 0; i < encoded.length; i++) {
    encoded[i].options = (0, _merge2.default)(options, encoded[i].options);
  }

  return encoded;
}

function autoSelectBarcode() {
  // If CODE128 exists. Use it
  if (_barcodes2.default["CODE128"]) {
    return "CODE128";
  }

  // Else, take the first (probably only) barcode
  return Object.keys(_barcodes2.default)[0];
}

// Sets global encoder options
// Added to the api by the JsBarcode function
API.prototype.options = function (options) {
  this._options = (0, _merge2.default)(this._options, options);
  return this;
};

// Will create a blank space (usually in between barcodes)
API.prototype.blank = function (size) {
  var zeroes = new Array(size + 1).join("0");
  this._encodings.push({ data: zeroes });
  return this;
};

// Initialize JsBarcode on all HTML elements defined.
API.prototype.init = function () {
  // Should do nothing if no elements where found
  if (!this._renderProperties) {
    return;
  }

  // Make sure renderProperies is an array
  if (!Array.isArray(this._renderProperties)) {
    this._renderProperties = [this._renderProperties];
  }

  var renderProperty;
  for (var i in this._renderProperties) {
    renderProperty = this._renderProperties[i];
    var options = (0, _merge2.default)(this._options, renderProperty.options);

    if (options.format == "auto") {
      options.format = autoSelectBarcode();
    }

    this._errorHandler.wrapBarcodeCall(function () {
      var text = options.value;
      var Encoder = _barcodes2.default[options.format.toUpperCase()];
      var encoded = encode(text, Encoder, options);

      render(renderProperty, encoded, options);
    });
  }
};

// The render API call. Calls the real render function.
API.prototype.render = function () {
  if (!this._renderProperties) {
    throw new _exceptions.NoElementException();
  }

  if (Array.isArray(this._renderProperties)) {
    for (var i = 0; i < this._renderProperties.length; i++) {
      render(this._renderProperties[i], this._encodings, this._options);
    }
  } else {
    render(this._renderProperties, this._encodings, this._options);
  }

  return this;
};

API.prototype._defaults = _defaults2.default;

// Prepares the encodings and calls the renderer
function render(renderProperties, encodings, options) {
  encodings = (0, _linearizeEncodings2.default)(encodings);

  for (var i = 0; i < encodings.length; i++) {
    encodings[i].options = (0, _merge2.default)(options, encodings[i].options);
    (0, _fixOptions2.default)(encodings[i].options);
  }

  (0, _fixOptions2.default)(options);

  var Renderer = renderProperties.renderer;
  var renderer = new Renderer(renderProperties.element, encodings, options);
  renderer.render();

  if (renderProperties.afterRender) {
    renderProperties.afterRender();
  }
}

// Export to browser
if (typeof window !== "undefined") {
  window.JsBarcode = JsBarcode;
}

// Export to jQuery
/*global jQuery */
if (typeof jQuery !== "undefined") {
  jQuery.fn.JsBarcode = function (content, options) {
    var elementArray = [];
    jQuery(this).each(function () {
      elementArray.push(this);
    });
    return JsBarcode(elementArray, content, options);
  };
}

// Export to commonJS
module.exports = { JsBarcode: JsBarcode, barcodes: _barcodes2.default };