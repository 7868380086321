//
//  AppDescriptorStore.ts
//
//  Created by Peter Vu on 2022-05-06 17:02:34.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import {
    AppDescriptor,
    AuthenticationPage,
    CollectionPage,
    CustomPage,
    DefaultPagesMap,
    DetailsPage,
    FormPage,
    PageType,
} from '@appformula/app-descriptor'
import { Nullable } from '@appformula/shared-foundation-x'
import * as _ from 'lodash'
import { computed, makeObservable } from 'mobx'
import { AppDescriptorRepository } from './AppDescriptor.repo'
import {
    DefaultPageType,
    InspectorCollectionPageItemActionSelection,
    InspectorCollectionPageSearchBarSelection,
    InspectorCollectionPageSelection,
    InspectorDefaultPageComponentSelection,
    InspectorItemSelection,
    InspectorItemStore,
    InspectorPageComponentSelection,
} from './designer'
import {
    LexoRankContainer,
    LexoRankInsertionPosition,
} from './helpers/LexoRankContainer'
import { ObservableObjectStore } from './ObservableObjectStore'
import {
    CollectionPageRepository,
    CollectionPageStore,
    CustomPageRepository,
    CustomPageStore,
    FormPageRepository,
    FormPageStore,
    PageStore,
} from './pages'
import { DetailsPageStore } from './pages/details/DetailsPage'
import { DetailsPageRepository } from './pages/details/DetailsPage.repo'

export type WritableAppDescriptorKey = Omit<
    AppDescriptor,
    'defaultPages' | 'frontPageIds' | 'pages' | 'pagesPosition'
>

export class AppDescriptorStore extends ObservableObjectStore<AppDescriptor> {
    private repository: AppDescriptorRepository

    get authenticationPage(): Nullable<AuthenticationPage> {
        return this.object?.authenticationPage
    }

    get accentColor(): string {
        return this.object?.accentColor ?? ''
    }

    get homeScreenTitle(): string {
        return this.object?.homeScreenTitle ?? ''
    }

    get frontPageIds(): string[] {
        return Object.keys(this.object?.frontPageIds ?? {}).sort((lhs, rhs) => {
            const lhsRank = this.object?.pagesPosition?.[lhs] ?? ''
            const rhsRank = this.object?.pagesPosition?.[rhs] ?? ''
            return lhsRank.localeCompare(rhsRank)
        })
    }

    get nonFrontPageIds(): string[] {
        return _.differenceWith(this.allPageIds, this.frontPageIds)
    }

    get defaultPages(): DefaultPagesMap {
        return this.object?.defaultPages
    }

    get allPageIds(): string[] {
        return Object.keys(this.object?.pages ?? {}).sort((lhs, rhs) => {
            const lhsRank = this.object?.pagesPosition?.[lhs] ?? ''
            const rhsRank = this.object?.pagesPosition?.[rhs] ?? ''
            return lhsRank.localeCompare(rhsRank)
        })
    }

    get pageTypes(): Record<string, PageType> {
        return _.mapValues(this.object?.pages, (e) => e.type)
    }

    private pageStoreCache: Map<string, PageStore> = new Map()
    private defaultPageStoreCache: Map<string, PageStore> = new Map()

    constructor(repository: AppDescriptorRepository) {
        super(repository.appDescriptor, repository.appDescriptorSnapshot)
        this.repository = repository
        makeObservable(this, {
            accentColor: computed,
            homeScreenTitle: computed,
            frontPageIds: computed.struct,
            allPageIds: computed.struct,
            pageTypes: computed.struct,
            authenticationPage: computed.struct,
        })
    }

    pageStore(pageId: string): Nullable<PageStore> {
        const pageType = this.pageTypes[pageId]
        if (pageType) {
            const cachedPageStore = this.pageStoreCache.get(pageId)
            if (cachedPageStore) {
                return cachedPageStore
            } else {
                const newPageStore = this.makePageStore(pageId, pageType)
                this.pageStoreCache.set(pageId, newPageStore)
                return newPageStore
            }
        } else {
            return undefined
        }
    }

    defaultPageStore(
        tableId: string,
        type: DefaultPageType
    ): Nullable<PageStore> {
        if (type) {
            const keys = Object.keys(this.defaultPages[tableId])
            switch (type) {
                case 'details':
                default: {
                    const cachedPageStore = this.defaultPageStoreCache.get(
                        keys[0]
                    )
                    if (cachedPageStore) {
                        return cachedPageStore
                    } else {
                        const newPageStore = this.makeDefaultPageStore(
                            tableId,
                            type
                        )
                        this.defaultPageStoreCache.set(
                            `${tableId}-${keys[0]}`,
                            newPageStore
                        )
                        return newPageStore
                    }
                }
                case 'form': {
                    const cachedPageStore = this.defaultPageStoreCache.get(
                        keys[1]
                    )
                    if (cachedPageStore) {
                        return cachedPageStore
                    } else {
                        const newPageStore = this.makeDefaultPageStore(
                            tableId,
                            type
                        )
                        this.defaultPageStoreCache.set(
                            `${tableId}-${keys[1]}`,
                            newPageStore
                        )
                        return newPageStore
                    }
                }
            }
        } else {
            return undefined
        }
    }

    inspectorItemStore(
        selection: InspectorItemSelection
    ): Nullable<InspectorItemStore> {
        if (selection instanceof InspectorPageComponentSelection) {
            const pageType = this.pageTypes[selection.pageId]
            if (!pageType) {
                return undefined
            }

            switch (pageType) {
                case 'collection':
                    return undefined
                case 'custom':
                case 'details': {
                    const customPageStore = this.pageStore(
                        selection.pageId
                    ) as unknown as CustomPageStore | DetailsPageStore
                    return customPageStore.componentStore(selection.componentId)
                }

                case 'form': {
                    const formPageStore = this.pageStore(
                        selection.pageId
                    ) as unknown as FormPageStore
                    return formPageStore.componentStore(selection.componentId)
                }
                default:
                    return undefined
            }
        } else if (
            selection instanceof InspectorCollectionPageSearchBarSelection
        ) {
            const collectionPageStore = this.pageStore(
                selection.collectionPageId
            )
            if (collectionPageStore instanceof CollectionPageStore) {
                return collectionPageStore.searchBarStore()
            }
        } else if (
            selection instanceof InspectorCollectionPageItemActionSelection
        ) {
            const collectionPageStore = this.pageStore(
                selection.collectionPageId
            )
            if (collectionPageStore instanceof CollectionPageStore) {
                return collectionPageStore.itemActionStore(selection.actionId)
            }
        } else if (selection instanceof InspectorCollectionPageSelection) {
            const collectionPageStore = this.pageStore(
                selection.collectionPageId
            )
            if (collectionPageStore instanceof CollectionPageStore) {
                return collectionPageStore
            }
        } else if (
            selection instanceof InspectorDefaultPageComponentSelection
        ) {
            const { pageType, tableId } = selection
            switch (pageType) {
                case 'details': {
                    const customPageStore = this.defaultPageStore(
                        tableId,
                        pageType
                    ) as unknown as DetailsPageStore
                    return customPageStore.componentStore(selection.componentId)
                }
                case 'form': {
                    const formPageStore = this.defaultPageStore(
                        tableId,
                        pageType
                    ) as unknown as FormPageStore
                    return formPageStore.componentStore(selection.componentId)
                }
                default:
                    break
            }
        }

        return undefined
    }

    private makePageStore(pageId: string, pageType: PageType): PageStore {
        const pageRepo = this.repository.pageRepositoryOf(pageId, pageType)
        switch (pageType) {
            case 'collection':
            default:
                return new CollectionPageStore(
                    pageRepo as unknown as CollectionPageRepository
                )
            case 'custom':
                return new CustomPageStore(
                    pageRepo as unknown as CustomPageRepository
                )
            case 'details':
                return new DetailsPageStore(
                    pageRepo as unknown as DetailsPageRepository
                )
            case 'form':
                return new FormPageStore(
                    pageRepo as unknown as FormPageRepository
                )
        }
    }

    private makeDefaultPageStore(
        tableId: string,
        pageType: DefaultPageType
    ): PageStore {
        const pageRepo = this.repository.defaultPageRepositoryOf(
            tableId,
            pageType
        )
        switch (pageType) {
            case 'details':
                return new DetailsPageStore(
                    pageRepo as unknown as DetailsPageRepository
                )
            case 'form':
                return new FormPageStore(
                    pageRepo as unknown as FormPageRepository
                )
        }
    }

    addPage(
        newPageId: string,
        page: CollectionPage | CustomPage | DetailsPage | FormPage,
        isFrontPage: boolean
    ): Promise<void> {
        // const newPageId = nanoid(8)
        const lexoRankContainer = new LexoRankContainer(
            this.allPageIds,
            this.object?.pagesPosition ?? {}
        )
        const rankForNewPage = lexoRankContainer.insertionRank(
            'below',
            _.last(this.allPageIds)
        )
        return this.repository.insertPage(
            newPageId,
            page,
            rankForNewPage,
            isFrontPage
        )
    }

    removePageId(pageId: string): Promise<void> {
        return this.repository.removePageId(pageId)
    }

    movePageId(
        pageId: string,
        position: LexoRankInsertionPosition,
        targetPageId: Nullable<string>
    ): Promise<void> {
        const lexoRankContainer = new LexoRankContainer(
            this.allPageIds,
            this.object?.pagesPosition ?? {}
        )
        const newRank = lexoRankContainer.insertionRank(position, targetPageId)
        return this.repository.setPageRank(pageId, newRank)
    }

    addPageToFrontPages(pageId: string): Promise<void> {
        return this.repository.addPageToFrontPages(pageId)
    }

    removePageFromFrontPages(pageId: string): Promise<void> {
        return this.repository.removePageFromFrontPages(pageId)
    }

    set<K extends keyof WritableAppDescriptorKey>(
        key: K,
        value: AppDescriptor[K]
    ): Promise<void> {
        return this.repository.set(key, value)
    }

    setAuthenticationProps<K extends keyof AuthenticationPage>(
        key: K,
        value: AuthenticationPage[K]
    ): Promise<void> {
        const authenticationPage = this.object.authenticationPage
        if (authenticationPage) {
            authenticationPage[key] = value
        }
        return this.repository.setAuthenticationPageProps(key, value)
    }
}
