//
//  FilePreview.ts
//
//  Created by Peter Vu on 2022-05-06 17:04:16.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { Binding, FilePreview } from '@appformula/app-descriptor'
import { Nullable } from '@appformula/shared-foundation-x'
import { computed, makeObservable } from 'mobx'
import { ContentExportableComponentStore } from '.'
import { ControlComponentStore } from './Base'
import { PageComponentRepository } from './Base.repo'

export type FilePreviewRepository = PageComponentRepository<FilePreview>

export class FilePreviewStore
    extends ControlComponentStore<FilePreview>
    implements ContentExportableComponentStore
{
    get fileUrl(): Nullable<Binding<string>> {
        return this.object?.fileUrl
    }

    constructor(repository: FilePreviewRepository) {
        super(repository)
        makeObservable(this, {
            fileUrl: computed.struct,
        })
    }
    get exportPropNames(): string[] {
        return ['fileUrl']
    }
    exportedData(props: keyof FilePreview): string[] {
        return [this.componentId, props]
    }
}
