//
//  NumberScaleInput.ts
//
//  Created by Peter Vu on 2022-05-06 17:04:36.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import {
    Binding,
    NumberScaleInput,
    ScaleIcon,
} from '@appformula/app-descriptor'
import { Nullable } from '@appformula/shared-foundation-x'
import { computed, makeObservable } from 'mobx'
import { ContentExportableComponentStore } from '.'
import { ControlComponentStore } from './Base'
import { PageComponentRepository } from './Base.repo'

export type NumberScaleInputRepository =
    PageComponentRepository<NumberScaleInput>

export class NumberScaleInputStore
    extends ControlComponentStore<NumberScaleInput>
    implements ContentExportableComponentStore
{
    get title(): Nullable<Binding<string>> {
        return this.object?.title
    }

    get initialValue(): Nullable<Binding<number>> {
        return this.object?.initialValue
    }

    get scaleIcon(): ScaleIcon {
        return this.object?.scaleIcon ?? 'star'
    }

    constructor(repository: NumberScaleInputRepository) {
        super(repository)
        makeObservable(this, {
            title: computed.struct,
            initialValue: computed.struct,
            scaleIcon: computed,
        })
    }
    get exportPropNames(): string[] {
        return ['title', 'initialValue']
    }
    exportedData(props: keyof NumberScaleInput): string[] {
        return [this.componentId, props]
    }
}
