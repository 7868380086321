//
//  ObservableObjectStore.ts
//
//  Created by Peter Vu on 2022-05-06 17:02:28.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { action, makeObservable, observable, onBecomeObserved, onBecomeUnobserved } from 'mobx'
import { Observable, Subscription } from 'rxjs'
import { Nullable } from '@appformula/shared-foundation-x'

export class ObservableObjectStore<O> {
    object: Nullable<O>
    private objectSubscription: Nullable<Subscription>

    constructor(objectObservable: Observable<Nullable<O>>, objectSnapshot: Nullable<O> = undefined) {
        this.object = objectSnapshot

        makeObservable(
            this,
            {
                object: observable,
                __onObjectChanged: action.bound,
            },
            {
                autoBind: true,
            }
        )

        onBecomeObserved(this, 'object', () => {
            this.objectSubscription = objectObservable.subscribe(this.__onObjectChanged)
        })
        onBecomeUnobserved(this, 'object', () => {
            this.objectSubscription?.unsubscribe()
        })
    }

    __onObjectChanged(newValue: Nullable<O>) {
        this.object = newValue
    }
}
