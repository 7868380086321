//
//  page.ts
//
//  Created by Peter Vu on 2022-04-14 11:40:47.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { CollectionPage } from './collection'
import { CustomPage } from './custom'
import { DetailsPage } from './details'
import { FormPage } from './form'

export * from './authentication'
export * from './collection'
export * from './custom'
export * from './details'
export * from './form'
export * from './Base'
export * from './ComponentContainer'

export type Page = CollectionPage | CustomPage | DetailsPage | FormPage
