//
//  Binding.ts
//
//  Created by Peter Vu on 2022-04-14 14:56:55.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

export type BindableType = string | boolean | number | Date
export type BindingValue = BindableType | BindableType[]
export type BindingSource =
    | 'constant'
    | 'formula'
    | 'contexualMapping'
    | 'keyPath'

export type Binding<T extends BindingValue> =
    | ConstantBinding<T>
    | FormulaBinding
    | ContextualMappingBinding
    | KeyPathBinding

export type BindingTypeName =
    | 'string'
    | 'boolean'
    | 'number'
    | 'date'
    | 'string[]'
    | 'boolean[]'
    | 'number[]'
    | 'date[]'

export interface BaseBinding {
    source: BindingSource
    valueTypeName: BindingTypeName
}

export class ConstantBinding<T extends BindingValue> implements BaseBinding {
    readonly source: BindingSource
    readonly valueTypeName: BindingTypeName
    constantValue: T

    private constructor(value: T, valueTypeName: BindingTypeName) {
        this.source = 'constant'
        this.constantValue = value
        this.valueTypeName = valueTypeName
    }

    static string(constantValue: string): ConstantBinding<string> {
        return new ConstantBinding(constantValue, 'string')
    }

    static stringArray(constantValue: string[]): ConstantBinding<string[]> {
        return new ConstantBinding(constantValue, 'string[]')
    }

    static number(constantValue: number): ConstantBinding<number> {
        return new ConstantBinding(constantValue, 'number')
    }

    static numberArray(constantValue: number[]): ConstantBinding<number[]> {
        return new ConstantBinding(constantValue, 'number[]')
    }

    static boolean(constantValue: boolean): ConstantBinding<boolean> {
        return new ConstantBinding(constantValue, 'boolean')
    }

    static booleanArray(constantValue: boolean[]): ConstantBinding<boolean[]> {
        return new ConstantBinding(constantValue, 'boolean[]')
    }

    static date(constantValue: Date): ConstantBinding<Date> {
        return new ConstantBinding(constantValue, 'date')
    }

    static dateArray(constantValue: Date[]): ConstantBinding<Date[]> {
        return new ConstantBinding(constantValue, 'date[]')
    }
}

export class FormulaBinding implements BaseBinding {
    source: BindingSource
    valueTypeName: BindingTypeName
    formulaString: string

    constructor(formulaString: string, valueTypeName: BindingTypeName) {
        this.source = 'formula'
        this.formulaString = formulaString
        this.valueTypeName = valueTypeName
    }
}

export class ContextualMappingBinding implements BaseBinding {
    objectType: 'currentRow' | 'currentUser' | 'currentUserAddress'
    mappingPropName: string
    source: BindingSource
    valueTypeName: BindingTypeName

    constructor(
        objectType: 'currentRow' | 'currentUser' | 'currentUserAddress',
        mappingPropName: string,
        valueTypeName: BindingTypeName
    ) {
        this.objectType = objectType
        this.mappingPropName = mappingPropName
        this.source = 'contexualMapping'
        this.valueTypeName = valueTypeName
    }
}

export class KeyPathBinding implements BaseBinding {
    keyPath: string[]
    source: BindingSource
    valueTypeName: BindingTypeName

    constructor(keyPath: string[], valueTypeName: BindingTypeName) {
        this.keyPath = keyPath
        this.source = 'keyPath'
        this.valueTypeName = valueTypeName
    }
}
