import {
    DataSourceProvider,
    FieldTableType,
    Table,
    TableSchema,
} from '@appformula/app-descriptor'
import { Nullable } from '@appformula/shared-foundation-x'
import { makeObservable, computed } from 'mobx'
import { Observable } from 'rxjs'
import { ObservableObjectStore } from '../ObservableObjectStore'

export interface TableRepository {
    get tableSnapshot(): Nullable<Table>
    get table(): Observable<Table>
    get tableId(): string

    set<K extends keyof Table>(key: K, value: Table[K]): Promise<void>

    setColumnType(columnName: string, newType: FieldTableType): Promise<void>
}

export class TableStore extends ObservableObjectStore<Table> {
    get id(): string {
        return this.repository.tableId
    }

    get name(): Nullable<string> {
        return this.object?.name
    }

    get schema(): TableSchema {
        return this.object?.schema ?? { primaryField: '', fieldType: {} }
    }

    get dataProviderType(): Nullable<DataSourceProvider> {
        return this.object?.provider
    }

    private repository: TableRepository

    constructor(repository: TableRepository) {
        super(repository.table, repository.tableSnapshot)
        this.repository = repository

        makeObservable(this, {
            name: computed,
            schema: computed.struct,
            dataProviderType: computed,
        })
    }

    setColumnType(columnName: string, newType: FieldTableType): Promise<void> {
        return this.repository.setColumnType(columnName, newType)
    }
}
