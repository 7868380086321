//
//  Files.ts
//
//  Created by Peter Vu on 2022-05-25 10:53:06.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { FilesFormInput, FileType } from '@appformula/app-descriptor'
import { FilesFormInputRepository } from '@appformula/app-studio-core'
import { FirebaseFormInputRepository } from '../FormInputRepository'

export class FirebaseFilesFormInputRepository
    extends FirebaseFormInputRepository<FilesFormInput>
    implements FilesFormInputRepository
{
    addExcludeFileType(fileType: FileType): Promise<void> {
        return this.ref.child('excludedFileTypes').child(fileType).set(true)
    }
    removeExcludeFileType(fileType: FileType): Promise<void> {
        return this.ref.child('excludedFileTypes').child(fileType).remove()
    }
}
