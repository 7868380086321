export class NormalPageSelection {
    readonly pageId: string

    constructor(pageId: string) {
        this.pageId = pageId
    }
}

export type DefaultPageType = 'details' | 'form'
export type DefaultAuthenticationPageType = 'welcome'
export class DefaultPageSelection {
    readonly dataSourceId: string
    readonly defaultPageType: DefaultPageType

    constructor(dataSourceId: string, defaultPageType: DefaultPageType) {
        this.dataSourceId = dataSourceId
        this.defaultPageType = defaultPageType
    }
}

export class DefaultAuthenticationPageSelection {
    readonly defaultAuthenticationPageType: DefaultAuthenticationPageType

    constructor(pageType: DefaultAuthenticationPageType) {
        this.defaultAuthenticationPageType = pageType
    }
}

export type PageSelection =
    | DefaultPageSelection
    | NormalPageSelection
    | DefaultAuthenticationPageSelection
