//
//  Files.ts
//
//  Created by Peter Vu on 2022-05-06 17:02:56.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { FilesFormInput, FileType, fileTypeFromString } from '@appformula/app-descriptor'
import { computed, makeObservable } from 'mobx'
import { BaseFormInputStore, BaseFormInputRepository } from './Base'
import { compact } from 'lodash'

export interface FilesFormInputRepository extends BaseFormInputRepository<FilesFormInput> {
    addExcludeFileType(fileType: FileType): Promise<void>
    removeExcludeFileType(fileType: FileType): Promise<void>
}

export class FilesFormInputStore extends BaseFormInputStore<FilesFormInput, FilesFormInputRepository> {
    get excludedFileTypes(): FileType[] {
        const allPossibleValues = Object.entries(this.object?.excludedFileTypes ?? {})
            .filter((e) => e[1])
            .map((e) => fileTypeFromString(e[0]))
        return compact(allPossibleValues)
    }

    constructor(repository: FilesFormInputRepository) {
        super(repository)
        makeObservable(this, {
            excludedFileTypes: computed.struct,
        })
    }

    addExcludeFileType(fileType: FileType): Promise<void> {
        return this.repository.addExcludeFileType(fileType)
    }

    removeExcludeFileType(fileType: FileType): Promise<void> {
        return this.repository.removeExcludeFileType(fileType)
    }
}
