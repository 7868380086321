//
//  AppDataSourceRepository.ts
//
//  Created by Peter Vu on 2022-05-25 10:52:37.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { AppDataSourceDescriptor, Table } from '@appformula/app-descriptor'
import { AppDataSourceRepository } from '@appformula/app-studio-core'
import { Nullable } from '@appformula/shared-foundation-x'
import { TableRepository } from '@appformula/app-studio-core'
import { map, Observable } from 'rxjs'
import firebase from 'firebase'
import { collectionSnapshot } from '@appformula/shared-rx-firebase'
import { AnyObservableObjectRepository } from './ObjectRepository'
import { FirebaseTableRepository } from './data/TableRepository'

export class FirebaseAppDataSourceRepository
    extends AnyObservableObjectRepository<AppDataSourceDescriptor>
    implements AppDataSourceRepository
{
    get appDataSourceDescriptorSnapshot(): Nullable<AppDataSourceDescriptor> {
        return this.objectSnapshot
    }

    get appDataSourceDescriptor(): Observable<AppDataSourceDescriptor> {
        return this.object
    }

    private tableCollectionRef: firebase.firestore.CollectionReference

    constructor(tableCollectionRef: firebase.firestore.CollectionReference, appId: string) {
        const firestoreDescriptor = collectionSnapshot(tableCollectionRef).pipe(
            map((snapshot): AppDataSourceDescriptor => {
                const tables: Record<string, Table> = {}
                snapshot.docs
                    .filter((e) => e.exists)
                    .forEach((e) => {
                        const table = e.data() as unknown as Table
                        if (table) {
                            tables[e.id] = table
                        }
                    })
                return {
                    tables: tables,
                }
            })
        )

        const appDataSourceObjectID = `AppDataSource_${appId}`
        super(firestoreDescriptor, undefined, appDataSourceObjectID)
        this.tableCollectionRef = tableCollectionRef
    }

    addTable(tableId: string, table: Table): Promise<void> {
        return this.tableCollectionRef.doc(tableId).set(table)
    }

    remove(tableId: string): Promise<void> {
        return this.tableCollectionRef.doc(tableId).delete()
    }

    tableRepository(tableId: string): TableRepository {
        const tableDocRef = this.tableCollectionRef.doc(tableId)
        return new FirebaseTableRepository(tableDocRef, undefined, tableId)
    }
}
