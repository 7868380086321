//
//  BarcodeScanner.ts
//
//  Created by Peter Vu on 2022-05-25 10:53:08.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { BarcodeScannerFormInput, BarcodeType } from '@appformula/app-descriptor'
import { BarcodeScannerFormInputRepository } from '@appformula/app-studio-core'
import { FirebaseFormInputRepository } from '../FormInputRepository'

export class FirebaseBarcodeScannerFormInputRepository
    extends FirebaseFormInputRepository<BarcodeScannerFormInput>
    implements BarcodeScannerFormInputRepository
{
    addExcludeDetectedCodeType(codeType: BarcodeType): Promise<void> {
        return this.ref.child('excludeDetectedCodeTypes').child(codeType).set(true)
    }

    removeExcludeDetectedCodeType(codeType: BarcodeType): Promise<void> {
        return this.ref.child('excludeDetectedCodeTypes').child(codeType).remove()
    }
}
