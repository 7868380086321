//
//  ProgressBar.ts
//
//  Created by Peter Vu on 2022-05-06 17:04:48.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import {
    Binding,
    ConstantBinding,
    ProgressBar,
    ProgressBarStyle,
} from '@appformula/app-descriptor'
import { computed, makeObservable } from 'mobx'
import { ContentExportableComponentStore } from '.'
import { BasePageComponentStore } from './Base'
import { PageComponentRepository } from './Base.repo'

export type ProgressBarRepository = PageComponentRepository<ProgressBar>

export class ProgressBarStore
    extends BasePageComponentStore<ProgressBar, ProgressBarRepository>
    implements ProgressBar, ContentExportableComponentStore
{
    get progress(): Binding<number> {
        return this.object?.progress ?? ConstantBinding.number(0)
    }

    get style(): ProgressBarStyle {
        return this.object?.style ?? 'bar'
    }

    constructor(repository: ProgressBarRepository) {
        super(repository)
        makeObservable(this, {
            progress: computed.struct,
            style: computed,
        })
    }

    get exportPropNames(): string[] {
        return ['progress']
    }

    exportedData(props: keyof ProgressBar): string[] {
        return [this.componentId, props]
    }
}
