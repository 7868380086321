import { DetailsPage } from '@appformula/app-descriptor'
import { computed, makeObservable } from 'mobx'
import { BaseCustomPageStore } from '../custom/Base'
import { DetailsPageRepository } from './DetailsPage.repo'

export class DetailsPageStore extends BaseCustomPageStore<DetailsPage, DetailsPageRepository> {
    get tableId(): string {
        return this.object?.tableId ?? ''
    }

    constructor(repository: DetailsPageRepository) {
        super(repository)
        makeObservable(this, {
            tableId: computed,
        })
    }
}
