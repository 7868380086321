//
//  BarcodeScanner.ts
//
//  Created by Peter Vu on 2022-04-14 10:54:15.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { Nullable } from '@appformula/shared-foundation-x'
import { BaseFormInput } from './Base'

export interface BarcodeScannerFormInput extends BaseFormInput {
    excludeDetectedCodeTypes: Record<BarcodeType, boolean>
}

const BarcodeTypes = [
    'qr',
    'code11', // expo bar-code-scanner not supported
    'code39',
    'code93',
    'code128',
    'ean-13',
    'ean-8',
    'jan', // expo bar-code-scanner not supported
    'upc-e',
    'upc-a', // expo bar-code-scanner not supported for ios
] as const
export type BarcodeType = typeof BarcodeTypes[number]

export function barcodeTypeFromString(string: string): Nullable<BarcodeType> {
    return BarcodeTypes.find((validName) => validName === string)
}
