//
//  CollectionPage.ts
//
//  Created by Peter Vu on 2022-05-06 17:05:14.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import {
    Action,
    ActionType,
    Binding,
    CalendarLayout,
    CardLayout,
    CollectionPage,
    ColumnBinding,
    ColumnBindingClause,
    ConstantBinding,
    DataSource,
    DataSourceFilter,
    Filter,
    FilterClause,
    FilterType,
    FormulaFilterBinding,
    ItemAction,
    Layout,
    LayoutType,
    ListLayout,
    MapLayout,
    SearchConfig,
    SortColumn,
    SortOrder,
} from '@appformula/app-descriptor'
import { ActionIcon } from '@appformula/app-descriptor/src/lib/shared-types/ActionIcon'
import { Nullable } from '@appformula/shared-foundation-x'
import { LexoRank } from 'lexorank'
import * as _ from 'lodash'
import { action, computed, makeObservable } from 'mobx'
import { nanoid } from 'nanoid'
import { v4 as uuidv4 } from 'uuid'
import { LexoRankContainer } from '../../helpers/LexoRankContainer'
import { ObservableObjectStore } from '../../ObservableObjectStore'
import { BasePageStore } from '../Base'
import {
    CollectionPageItemActionRepository,
    CollectionPageRepository,
    CollectionPageSearchBarRepository,
} from './CollectionPage.repo'
export class CollectionPageStore extends BasePageStore<
    CollectionPage,
    CollectionPageRepository
> {
    get activeLayout(): Layout {
        switch (this.object?.layout.activeLayoutType) {
            case 'list':
                return this.object?.layout.listLayout ?? this.defaultLayout
            case 'calendar':
                return this.object?.layout.calendarLayout ?? this.defaultLayout
            case 'map':
                return this.object?.layout.mapLayout ?? this.defaultLayout
            case 'card':
                return this.object?.layout.cardLayout ?? this.defaultLayout
            default:
                return this.object?.layout.listLayout ?? this.defaultLayout
        }
    }

    get activeLayoutType(): LayoutType {
        return this.object?.layout.activeLayoutType ?? 'list'
    }

    get sortColumns(): SortColumn[] {
        this.object?.preSortPosition
        const positions = this.object?.preSortPosition ?? {}

        return Object.entries(this.object?.preSort ?? {})
            .sort((lhs, rhs) => {
                const lhsPosition = positions[lhs[0]] ?? ''
                const rhsPosition = positions[rhs[0]] ?? ''
                return lhsPosition.localeCompare(rhsPosition)
            })
            .map((e) => e[1])
    }

    get dataSource(): Nullable<DataSource> {
        return this.object?.dataSource
    }

    get searchConfig(): SearchConfig {
        const search = this.object?.search
        return {
            barcodeSearchEnabled: search?.barcodeSearchEnabled ?? false,
            matchingColumns: search?.matchingColumns ?? [],
            barcodeSearchColumn: search?.barcodeSearchColumn,
        }
    }

    get itemActions(): ItemAction[] {
        return Object.entries(this.object?.itemActions ?? {})
            .sort((lhs, rhs) => lhs[1].rank.localeCompare(rhs[1].rank))
            .map((entry): ItemAction => {
                return entry[1]
            })
    }

    get itemActionIds(): string[] {
        return Object.keys(this.object?.itemActions ?? {})
    }

    get preFilter(): Nullable<DataSourceFilter> {
        return this.object?.preFilter
    }

    get preSort(): [string, SortColumn][] {
        this.object?.preSortPosition
        const positions = this.object?.preSortPosition ?? {}

        return Object.entries(this.object?.preSort ?? {}).sort((lhs, rhs) => {
            const lhsPosition = positions[lhs[0]] ?? ''
            const rhsPosition = positions[rhs[0]] ?? ''
            return lhsPosition.localeCompare(rhsPosition)
        })
        // .map((e) => e[1])
    }

    private itemActionStoreCache: Map<string, CollectionPageItemActionStore> =
        new Map()

    constructor(repository: CollectionPageRepository) {
        super(repository)

        makeObservable(this, {
            activeLayout: computed.struct,
            dataSource: computed.struct,
            sortColumns: computed.struct,
            itemActions: computed.struct,
            itemActionIds: computed.struct,
            activeLayoutType: computed,
            setActiveLayoutType: action,
            addItemAction: action,
            removeItemAction: action,
            setCardLayoutProp: action,
            setMapLayoutProp: action,
            setCalendarLayoutProp: action,
            setListLayoutProp: action,
            addSortColumn: action,
            searchConfig: computed.struct,
            preFilter: computed.struct,
            preSort: computed.struct,
        })
    }

    // MARK: - Search Bar
    searchBarStore(): CollectionPageSearchBarStore {
        return new CollectionPageSearchBarStore(
            this.repository.searchBarRepository()
        )
    }

    // MARK: - Item Actions
    itemActionStore(
        itemActionId: string
    ): Nullable<CollectionPageItemActionStore> {
        const cachedStore = this.itemActionStoreCache.get(itemActionId)
        if (cachedStore) {
            return cachedStore
        } else {
            const newRepo = this.repository.itemActionRepository(itemActionId)
            const newStore = new CollectionPageItemActionStore(newRepo)
            this.itemActionStoreCache.set(itemActionId, newStore)
            return newStore
        }
    }

    addItemAction(itemAction: ItemAction): Promise<void> {
        const newActionId = uuidv4()
        return this.repository.addItemAction(newActionId, itemAction)
    }

    removeItemAction(itemActionId: string): Promise<void> {
        return this.repository.removeItemAction(itemActionId)
    }

    setItemActionTitle(itemActionId: string, title: string): Promise<void> {
        return this.repository.setItemActionTitle(itemActionId, title)
    }

    // MARK: - Layout
    setActiveLayoutType(newLayoutType: LayoutType): Promise<void> {
        if (this.object) {
            this.object.layout.activeLayoutType = newLayoutType
        }

        return this.repository.setActiveLayoutType(newLayoutType).then(() => {
            this.createLayoutIfNeeded(newLayoutType)
        })
    }

    private createLayoutIfNeeded(layoutType: LayoutType): Promise<void> {
        switch (layoutType) {
            case 'list':
                if (this.object?.layout.listLayout == undefined) {
                    const defaultListLayout: ListLayout = {
                        itemTitle: ConstantBinding.string('Hello'),
                    }
                    if (this.object) {
                        this.object.layout.listLayout = defaultListLayout
                    }
                    return this.repository.setListLayout(defaultListLayout)
                }
                break
            case 'card':
                if (this.object?.layout.cardLayout == undefined) {
                    const defaultCardLayout: CardLayout = {
                        itemTitle: ConstantBinding.string('Hello'),
                        imageSize: 'large',
                        contentPosition: 'block',
                    }
                    if (this.object) {
                        this.object.layout.cardLayout = defaultCardLayout
                    }
                    return this.repository.setCardLayout(defaultCardLayout)
                }
                break
            case 'calendar':
                if (this.object?.layout.calendarLayout == undefined) {
                    const defaultCalendarLayout: CalendarLayout = {
                        startDate: ConstantBinding.date(new Date()),
                        endDate: ConstantBinding.date(new Date()),
                        title: ConstantBinding.string('Hello'),
                    }

                    if (this.object) {
                        this.object.layout.calendarLayout =
                            defaultCalendarLayout
                    }
                    return this.repository.setCalendarLayout(
                        defaultCalendarLayout
                    )
                }
                break
            case 'map':
                if (this.object?.layout.mapLayout == undefined) {
                    const defaultMapLayout: MapLayout = {
                        address: ConstantBinding.string('Hello'),
                        title: ConstantBinding.string('Hello'),
                    }
                    if (this.object) {
                        this.object.layout.mapLayout = defaultMapLayout
                    }
                    return this.repository.setMapLayout(defaultMapLayout)
                }
                break
        }

        return Promise.resolve()
    }

    setCardLayoutProp<K extends keyof CardLayout>(
        key: K,
        value: CardLayout[K]
    ): Promise<void> {
        const cardLayout = this.object?.layout.cardLayout
        if (cardLayout) {
            cardLayout[key] = value
        }
        return this.repository.setCardLayoutProp(key, value)
    }

    setMapLayoutProp<K extends keyof MapLayout>(
        key: K,
        value: MapLayout[K]
    ): Promise<void> {
        const mapLayout = this.object?.layout.mapLayout
        if (mapLayout) {
            mapLayout[key] = value
        }
        return this.repository.setMapLayoutProp(key, value)
    }

    setCalendarLayoutProp<K extends keyof CalendarLayout>(
        key: K,
        value: CalendarLayout[K]
    ): Promise<void> {
        const calendarLayout = this.object?.layout.calendarLayout
        if (calendarLayout) {
            calendarLayout[key] = value
        }
        return this.repository.setCalendarLayoutProp(key, value)
    }

    setListLayoutProp<K extends keyof ListLayout>(
        key: K,
        value: ListLayout[K]
    ): Promise<void> {
        const listLayout = this.object?.layout.listLayout
        if (listLayout) {
            listLayout[key] = value
        }
        return this.repository.setListLayoutProp(key, value)
    }

    // MARK: - Sorting
    addSortColumn(column: SortColumn): Promise<void> {
        const lastColumn = this.sortColumns[this.sortColumns.length - 1]
        let columnRank: LexoRank = LexoRank.middle()

        if (lastColumn) {
            const lastColumnRank =
                this.object?.preSortPosition?.[lastColumn.columnName]
            if (lastColumnRank) {
                columnRank = LexoRank.parse(lastColumnRank).genNext()
            }
        }
        return this.repository.insertSortColumn(
            column.columnName,
            column.order,
            columnRank.toString()
        )
    }

    removeSortColumn(columnId: string): Promise<void> {
        return this.repository.removeSortColumnId(columnId)
    }

    setColumnOrder(columnName: string, order: SortOrder): Promise<void> {
        return this.repository.setSortColumnProp('order', order, columnName)
    }

    insertColumn(
        column: SortColumn,
        position: 'below' | 'above',
        targetColumnName: Nullable<string>
    ): Promise<void> {
        const columnId = column.columnName
        let newRank: LexoRank = LexoRank.middle()

        if (targetColumnName) {
            const targetColumnRank = LexoRank.parse(
                this.object?.preSortPosition?.[targetColumnName] ?? ''
            )
            switch (position) {
                case 'above': {
                    newRank = targetColumnRank.genPrev()
                    break
                }
                case 'below': {
                    newRank = targetColumnRank.genNext()
                    break
                }
            }
        }

        return this.repository.insertSortColumn(
            columnId,
            column.order,
            newRank.toString()
        )
    }

    setActiveFilterType(filterType: FilterType): Promise<void> {
        if (this.object) {
            this.object.preFilter.type = filterType
        }

        return this.repository.setActiveFilterType(filterType)
    }

    insertPreFilter(
        preFilterId: string,
        type: FilterType,
        filter: Filter | FormulaFilterBinding
    ): Promise<void> {
        const preFilterIds: string[] = []
        const priorities: Record<string, string> = {}
        switch (type) {
            case 'custom':
                {
                    if (this.object?.preFilter?.columnFilter) {
                        Object.entries(
                            this.object.preFilter.columnFilter
                        ).forEach(([key, value]) => {
                            preFilterIds.push(key)
                            priorities[key] = value.priority
                        })
                    }
                }
                break
            case 'formula':
                {
                    if (this.object?.preFilter?.formulaFilter) {
                        Object.entries(
                            this.object.preFilter.formulaFilter
                        ).forEach(([key, value]) => {
                            preFilterIds.push(key)
                            priorities[key] = value.priority
                        })
                    }
                }
                break
            default:
                break
        }

        const lexoRankContainer = new LexoRankContainer(
            preFilterIds,
            priorities ?? {}
        )
        const rankForNewFilter = lexoRankContainer.insertionRank(
            'below',
            _.last(preFilterIds)
        )
        return this.repository.insertPreFilter(
            preFilterId,
            type,
            filter,
            rankForNewFilter
        )
    }

    addColumnFilter(
        columnFilterKey: string,
        value: ColumnBinding
    ): Promise<void> {
        const preFilter = this.object?.preFilter.columnFilter
        const columnFilterIds: string[] = []
        const priorities: Record<string, string> = {}
        const newBindingKey = nanoid(8)
        if (preFilter && preFilter[columnFilterKey]) {
            if (preFilter[columnFilterKey].binding) {
                Object.entries(preFilter[columnFilterKey].binding).forEach(
                    ([key, value]) => {
                        columnFilterIds.push(key)
                        priorities[key] = value.priority
                    }
                )
            }
            const lexoRankContainer = new LexoRankContainer(
                columnFilterIds,
                priorities ?? {}
            )
            const rankForNewFilter = lexoRankContainer.insertionRank(
                'below',
                _.last(columnFilterIds)
            )
            const columnBinding: ColumnBinding = {
                ...value,
                priority: rankForNewFilter,
            }
            // preFilter[columnFilterKey].binding = {
            //     ...preFilter[columnFilterKey].binding,
            //     [newBindingKey]: columnBinding,
            // }
            // preFilter[columnFilterKey].binding[newBindingKey] = columnBinding
            return this.repository.addColumnFilter(
                columnFilterKey,
                newBindingKey,
                columnBinding
            )
        }
        return this.repository.addColumnFilter(
            columnFilterKey,
            newBindingKey,
            value
        )
    }

    changeColumnFilterClause(
        columnFilterId: string,
        clause: FilterClause
    ): Promise<void> {
        return this.repository.changeColumnFilterClause(columnFilterId, clause)
    }

    changeColumnFilterBindingClause(
        columnFilterId: string,
        preFilterId: string,
        clause: ColumnBindingClause
    ): Promise<void> {
        return this.repository.changeColumnFilterBindingClause(
            columnFilterId,
            preFilterId,
            clause
        )
    }

    removeFilterGroup(preFilterId: string, type: FilterType): Promise<void> {
        return this.repository.removeFilterGroup(preFilterId, type)
    }

    removeFilterId(
        preFilterId: string,
        type: FilterType,
        columnFilterId?: string
    ): Promise<void> {
        return this.repository.removePreFilter(
            preFilterId,
            type,
            columnFilterId
        )
    }

    removeAllFilter(): Promise<void> {
        return this.repository.removeAllFilter()
    }

    private get defaultLayout(): Layout {
        const title = ConstantBinding.string('Title')
        const layout: ListLayout = {
            itemTitle: title,
        }
        return layout
    }
}

export class CollectionPageSearchBarStore extends ObservableObjectStore<SearchConfig> {
    private repository: CollectionPageSearchBarRepository
    get matchingColumns(): string[] {
        return this.object?.matchingColumns ?? []
    }

    get barcodeSearchEnabled(): boolean {
        return this.object?.barcodeSearchEnabled ?? false
    }

    get barcodeSearchColumn(): Nullable<string> {
        return this.object?.barcodeSearchColumn
    }

    setBarcodeSearchEnable(enable: boolean): Promise<void> {
        return this.repository.set('barcodeSearchEnabled', enable)
    }

    setMatchingColumns(columns: string[]): Promise<void> {
        return this.repository.set('matchingColumns', columns)
    }

    setBarcodeSearchColumn(columnId: string): Promise<void> {
        return this.repository.set('barcodeSearchColumn', columnId)
    }

    constructor(repository: CollectionPageSearchBarRepository) {
        super(repository.object, repository.objectSnapshot)
        this.repository = repository
        makeObservable(this, {
            matchingColumns: computed.struct,
            barcodeSearchEnabled: computed,
            barcodeSearchColumn: computed,
        })
    }
}

export class CollectionPageItemActionStore extends ObservableObjectStore<ItemAction> {
    private repository: CollectionPageItemActionRepository
    get title(): string {
        return this.object?.title ?? ''
    }

    get icon(): ActionIcon {
        return this.object?.icon ?? 'add'
    }

    get isHidden(): Binding<boolean> {
        return this.object?.isHidden ?? ConstantBinding.boolean(false)
    }

    get currentAction(): ActionType {
        return this.object?.onTapAction.actionType ?? 'openPage'
    }

    get onTapAction(): Nullable<Action> {
        return this.object.onTapAction
    }

    setTitle(newTitle: string): Promise<void> {
        return this.repository.set('title', newTitle)
    }

    setIsHidden(hidden: boolean): Promise<void> {
        return this.repository.set('isHidden', ConstantBinding.boolean(hidden))
    }

    setIcon(icon: ActionIcon): Promise<void> {
        return this.repository.set('icon', icon)
    }

    setAction(action: Action): Promise<void> {
        return this.repository.set('onTapAction', action)
    }

    setSpecificationAction<T extends Action, K extends keyof T>(
        key: K,
        value: T[K]
    ): Promise<void> {
        const tapAction: T = this.object.onTapAction as T
        if (tapAction) {
            tapAction[key] = value
        }
        return this.repository.set('onTapAction', tapAction)
    }

    setBaseActionProp<K extends keyof Action>(
        key: K,
        value: Action[K]
    ): Promise<void> {
        const tapAction = this.object.onTapAction
        if (tapAction) {
            tapAction[key] = value
        }

        return this.repository.set('onTapAction', tapAction)
    }

    constructor(repository: CollectionPageItemActionRepository) {
        super(repository.object, repository.objectSnapshot)
        this.repository = repository
        makeObservable(this, {
            title: computed,
            icon: computed,
            isHidden: computed.struct,
            currentAction: computed,
        })
    }
}
