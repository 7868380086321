//
//  DateTimeInput.ts
//
//  Created by Peter Vu on 2022-05-06 17:04:11.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import {
    Binding,
    ConstantBinding,
    DateTimeInput,
} from '@appformula/app-descriptor'
import { Nullable } from '@appformula/shared-foundation-x'
import { computed, makeObservable } from 'mobx'
import { ContentExportableComponentStore } from '.'
import { ControlComponentStore } from './Base'
import { PageComponentRepository } from './Base.repo'

export type DateTimeInputRepository = PageComponentRepository<DateTimeInput>

export class DateTimeInputStore
    extends ControlComponentStore<DateTimeInput>
    implements ContentExportableComponentStore
{
    get title(): Binding<string> {
        return this.object?.title ?? ConstantBinding.string('')
    }

    get initialValue(): Nullable<Binding<Date>> {
        return this.object?.initialValue
    }

    constructor(repository: DateTimeInputRepository) {
        super(repository)
        makeObservable(this, {
            title: computed.struct,
            initialValue: computed.struct,
        })
    }
    get exportPropNames(): string[] {
        return ['title', 'initialValue']
    }
    exportedData(props: keyof DateTimeInput): string[] {
        return [this.componentId, props]
    }
}
