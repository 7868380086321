//
//  Photos.ts
//
//  Created by Peter Vu on 2022-04-14 10:54:41.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { Nullable } from '@appformula/shared-foundation-x'
import { BaseFormInput } from './Base'

export interface PhotosFormInput extends BaseFormInput {
    excludedSources: Record<PhotoSource, boolean>
}

const PhotoSources = ['photoLibrary', 'camera'] as const
export type PhotoSource = typeof PhotoSources[number]

export function photoSourceFromString(string: string): Nullable<PhotoSource> {
    return PhotoSources.find((validName) => validName === string)
}
