//
//  SingleLineText.ts
//
//  Created by Peter Vu on 2022-05-06 17:03:29.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { Binding, ContentType, FormulaValidator, SingleLineTextFormInput } from '@appformula/app-descriptor'
import { Nullable } from '@appformula/shared-foundation-x'
import { computed, makeObservable } from 'mobx'
import { BaseFormInputStore, BaseFormInputRepository } from './Base'

export type SingleLineTextFormInputRepository = BaseFormInputRepository<SingleLineTextFormInput>

export class SingleLineTextFormInputStore extends BaseFormInputStore<
    SingleLineTextFormInput,
    SingleLineTextFormInputRepository
> {
    get contentType(): ContentType {
        return this.object?.contentType ?? 'plain'
    }

    get defaultValue(): Nullable<Binding<string>> {
        return this.object?.defaultValue
    }

    get formulaValidator(): Nullable<FormulaValidator> {
        return this.object?.formulaValidator
    }

    constructor(repository: SingleLineTextFormInputRepository) {
        super(repository)
        makeObservable(this, {
            defaultValue: computed.struct,
            contentType: computed,
            formulaValidator: computed.struct,
        })
    }

    setValidatorProp<K extends keyof FormulaValidator>(key: K, value: FormulaValidator[K]): void {
        throw new Error('Method not implemented.')
    }
}
