//
//  DateRangeInput.ts
//
//  Created by Peter Vu on 2022-05-06 17:04:09.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import {
    Binding,
    ConstantBinding,
    DateRangeInput,
} from '@appformula/app-descriptor'
import { Nullable } from '@appformula/shared-foundation-x'
import { computed, makeObservable } from 'mobx'
import { ContentExportableComponentStore } from '.'
import { ControlComponentStore } from './Base'
import { PageComponentRepository } from './Base.repo'

export type DateRangeInputRepository = PageComponentRepository<DateRangeInput>

export class DateRangeInputStore
    extends ControlComponentStore<DateRangeInput>
    implements ContentExportableComponentStore
{
    get title(): Binding<string> {
        return this.object?.title ?? ConstantBinding.string('')
    }

    get initialStart(): Nullable<Binding<Date>> {
        return this.object?.initialStart
    }

    get initialEnd(): Nullable<Binding<Date>> {
        return this.object?.initialEnd
    }

    constructor(repository: DateRangeInputRepository) {
        super(repository)
        makeObservable(this, {
            title: computed.struct,
            initialStart: computed.struct,
            initialEnd: computed.struct,
        })
    }
    get exportPropNames(): string[] {
        return ['title', 'initialStart', 'initialEnd']
    }
    exportedData(props: keyof DateRangeInput): string[] {
        return [this.componentId, props]
    }
}
