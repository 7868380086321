import { delay, Observable } from 'rxjs'

export function dataSnapshot(
    query: firebase.default.database.Query,
    eventType: firebase.default.database.EventType = 'value'
): Observable<firebase.default.database.DataSnapshot> {
    return new Observable<firebase.default.database.DataSnapshot>((observer) => {
        const token = query.on(eventType, (snapshot) => {
            observer.next(snapshot)
        })

        return function tearDown() {
            query.off(eventType, token)
        }
    }).pipe(
        // Ensures subscribe on observable is async. This handles
        // a quirk in the SDK where on/once callbacks can happen
        // synchronously.
        delay(0)
    )
}
