//
//  WebLink.ts
//
//  Created by Peter Vu on 2022-05-06 17:05:00.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { Binding, WebLink } from '@appformula/app-descriptor'
import { Nullable } from '@appformula/shared-foundation-x'
import { computed, makeObservable } from 'mobx'
import { ContentExportableComponentStore } from '.'
import { BasePageComponentStore } from './Base'
import { PageComponentRepository } from './Base.repo'

export type WebLinkRepository = PageComponentRepository<WebLink>

export class WebLinkStore
    extends BasePageComponentStore<WebLink, WebLinkRepository>
    implements ContentExportableComponentStore
{
    get url(): Nullable<Binding<string>> {
        return this.object?.url
    }

    constructor(repository: WebLinkRepository) {
        super(repository)
        makeObservable(this, {
            url: computed.struct,
        })
    }
    get exportPropNames(): string[] {
        return ['url']
    }
    exportedData(props: keyof WebLink): string[] {
        return [this.componentId, props]
    }
}
