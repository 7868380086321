//
//  Base.ts
//
//  Created by Peter Vu on 2022-05-06 17:02:39.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import {
    BasePage,
    Binding,
    ConstantBinding,
    FrontPageIcon,
    PageType,
} from '@appformula/app-descriptor'
import { ObservableObjectStore } from '../ObservableObjectStore'
import { BasePageRepository, WritablePageKey } from './Base.repo'
import { Nullable } from '@appformula/shared-foundation-x'
import { computed, makeObservable } from 'mobx'

export class BasePageStore<
    P extends BasePage,
    R extends BasePageRepository<P>
> extends ObservableObjectStore<P> {
    readonly repository: R
    get pageId(): string {
        return this.repository.pageId
    }

    get title(): Binding<string> {
        return this.object?.title ?? ConstantBinding.string('')
    }

    get name(): string {
        return this.object?.name ?? ''
    }

    get badgeNumber(): Nullable<Binding<number>> {
        return this.object?.badgeNumber ?? ConstantBinding.number(0)
    }

    get pageType(): Nullable<PageType> {
        return this.object?.type
    }

    get pageIcon(): FrontPageIcon {
        return this.object?.frontPageIcon
    }

    get isHidden(): Nullable<Binding<boolean>> {
        return this.object?.isHidden ?? ConstantBinding.boolean(false)
    }

    constructor(repository: R) {
        super(repository.page, repository.pageSnapshot)
        this.repository = repository

        makeObservable(this, {
            title: computed.struct,
            name: computed,
            badgeNumber: computed.struct,
            pageType: computed,
            pageIcon: computed,
            isHidden: computed,
        })
    }

    async setValueOfKey<K extends keyof WritablePageKey<P>>(
        key: K,
        value: P[K]
    ): Promise<void> {
        this.willSet(key, value)
        await this.repository.set(key, value)
        this.didSet(key, value)
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected willSet<K extends keyof WritablePageKey<P>>(
        key: K,
        newValue: P[K]
    ): void {
        return
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected didSet<K extends keyof WritablePageKey<P>>(
        key: K,
        newValue: P[K]
    ): void {
        return
    }
}
