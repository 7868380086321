//
//  CustomPage.ts
//
//  Created by Peter Vu on 2022-05-06 17:05:05.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { CustomPage } from '@appformula/app-descriptor'
import { BaseCustomPageStore } from './Base'
import { BaseCustomPageRepository } from './Base.repo'
import { CustomPageRepository } from './CustomPage.repo'

export class CustomPageStore extends BaseCustomPageStore<CustomPage, BaseCustomPageRepository<CustomPage>> {
    constructor(repository: CustomPageRepository) {
        super(repository)
    }
}
