import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  title: {
    justifyContent: 'center',
    alignItems: 'center',
    borderTopWidth: 1,
  },
});

export default styles;
