//
//  FormPageRepository.ts
//
//  Created by Peter Vu on 2022-05-25 10:52:45.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import {
    BarcodeScannerFormInput,
    BooleanFormInput,
    DateTimeFormInput,
    FilesFormInput,
    FormInput,
    FormInputType,
    FormPage,
    LocationFormInput,
    LookupFormInput,
    MultiLineTextFormInput,
    NumberFormInput,
    OptionsFormInput,
    PeopleLookupFormInput,
    PhotosFormInput,
    SignatureDrawingFormInput,
    SingleLineTextFormInput,
} from '@appformula/app-descriptor'
import { FormInputRepository, FormPageRepository } from '@appformula/app-studio-core'
import { FirebaseBasePageRepository } from '../BaseRepository'
import { FirebaseFormInputRepository } from './FormInputRepository'
import { FirebaseBarcodeScannerFormInputRepository } from './inputs/BarcodeScanner'
import { FirebaseFilesFormInputRepository } from './inputs/Files'
import { FirebasePhotosFormInputRepository } from './inputs/Photos'

export class FirebaseFormPageRepository extends FirebaseBasePageRepository<FormPage> implements FormPageRepository {
    insertComponent(componentId: string, component: FormInput, position: string): Promise<void> {
        return this.ref.runTransaction((data) => {
            const componentsMap = data?.components ?? {}
            componentsMap[componentId] = component
            data.components = componentsMap

            const componentsPosition = data.componentsPosition ?? {}
            componentsPosition[componentId] = position
            data.componentsPosition = componentsPosition
            return data
        })
    }

    setComponentPosition(componentId: string, position: string): Promise<void> {
        return this.ref.child('componentsPosition').child(componentId).set(position)
    }

    removeComponent(componentId: string): Promise<void> {
        return this.ref.runTransaction((data) => {
            if (data) {
                delete data.componentsPosition?.[componentId]
                delete data.components?.[componentId]
            }
            return data
        })
    }

    componentRepository(componentId: string, componentType: FormInputType): FormInputRepository {
        const componentRef = this.ref.child('components').child(componentId).ref
        const component = this.objectSnapshot?.components?.[componentId]

        switch (componentType) {
            case 'options':
                return new FirebaseFormInputRepository<OptionsFormInput>(
                    componentRef,
                    component as unknown as OptionsFormInput,
                    componentId
                )
            case 'number':
                return new FirebaseFormInputRepository<NumberFormInput>(
                    componentRef,
                    component as unknown as NumberFormInput,
                    componentId
                )
            case 'boolean':
                return new FirebaseFormInputRepository<BooleanFormInput>(
                    componentRef,
                    component as unknown as BooleanFormInput,
                    componentId
                )
            case 'dateTime':
                return new FirebaseFormInputRepository<DateTimeFormInput>(
                    componentRef,
                    component as unknown as DateTimeFormInput,
                    componentId
                )
            case 'location':
                return new FirebaseFormInputRepository<LocationFormInput>(
                    componentRef,
                    component as unknown as LocationFormInput,
                    componentId
                )
            case 'lookup':
                return new FirebaseFormInputRepository<LookupFormInput>(
                    componentRef,
                    component as unknown as LookupFormInput,
                    componentId
                )
            case 'multiLineText':
                return new FirebaseFormInputRepository<MultiLineTextFormInput>(
                    componentRef,
                    component as unknown as MultiLineTextFormInput,
                    componentId
                )
            case 'singleLineText':
                return new FirebaseFormInputRepository<SingleLineTextFormInput>(
                    componentRef,
                    component as unknown as SingleLineTextFormInput,
                    componentId
                )
            case 'peopleLookup':
                return new FirebaseFormInputRepository<PeopleLookupFormInput>(
                    componentRef,
                    component as unknown as PeopleLookupFormInput,
                    componentId
                )
            case 'barcodeScanner':
                return new FirebaseBarcodeScannerFormInputRepository(
                    componentRef,
                    component as unknown as BarcodeScannerFormInput,
                    componentId
                )
            case 'files':
                return new FirebaseFilesFormInputRepository(
                    componentRef,
                    component as unknown as FilesFormInput,
                    componentId
                )
            case 'photos':
                return new FirebasePhotosFormInputRepository(
                    componentRef,
                    component as unknown as PhotosFormInput,
                    componentId
                )
            case 'signatureDrawing':
                return new FirebaseFormInputRepository<SignatureDrawingFormInput>(
                    componentRef,
                    component as unknown as SignatureDrawingFormInput,
                    componentId
                )
            default:
                throw Error('Unsupported form input type')
        }
    }
}
