//
//  Number.ts
//
//  Created by Peter Vu on 2022-05-06 17:03:10.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { Binding, FormulaValidator, NumberFormInput } from '@appformula/app-descriptor'
import { Nullable } from '@appformula/shared-foundation-x'
import { computed, makeObservable } from 'mobx'
import { BaseFormInputStore, BaseFormInputRepository } from './Base'

export type NumberFormInputRepository = BaseFormInputRepository<NumberFormInput>

export class NumberFormInputStore extends BaseFormInputStore<NumberFormInput, NumberFormInputRepository> {
    get defaultValue(): Nullable<Binding<number>> {
        return this.object?.defaultValue
    }

    get allowsFloatingNumber(): boolean {
        return this.object?.allowsFloatingNumber ?? false
    }

    get formulaValidator(): Nullable<FormulaValidator> {
        return this.object?.formulaValidator
    }

    constructor(repository: NumberFormInputRepository) {
        super(repository)
        makeObservable(this, {
            defaultValue: computed,
            allowsFloatingNumber: computed,
            formulaValidator: computed,
        })
    }

    setValidatorProp<K extends keyof FormulaValidator>(key: K, value: FormulaValidator[K]): void {
        throw new Error('Method not implemented.')
    }
}
