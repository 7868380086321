//
//  OptionsInput.ts
//
//  Created by Peter Vu on 2022-05-06 17:04:43.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { Binding, OptionsInput } from '@appformula/app-descriptor'
import { Nullable } from '@appformula/shared-foundation-x'
import { computed, makeObservable } from 'mobx'
import { ContentExportableComponentStore } from '.'
import { ControlComponentStore } from './Base'
import { PageComponentRepository } from './Base.repo'

export type OptionsInputRepository = PageComponentRepository<OptionsInput>

export class OptionsInputStore
    extends ControlComponentStore<OptionsInput>
    implements ContentExportableComponentStore
{
    get title(): Nullable<Binding<string>> {
        return this.object?.title
    }

    get availableOptions(): string[] {
        return this.object?.availableOptions ?? []
    }

    get allowsMultipleSelection(): boolean {
        return this.object?.allowsMultipleSelection ?? false
    }

    constructor(repository: OptionsInputRepository) {
        super(repository)
        makeObservable(this, {
            title: computed.struct,
            availableOptions: computed.struct,
            allowsMultipleSelection: computed,
        })
    }
    get exportPropNames(): string[] {
        return ['title']
    }
    exportedData(props: keyof OptionsInput): string[] {
        return [this.componentId, props]
    }
}
