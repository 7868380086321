//
//  Lookup.ts
//
//  Created by Peter Vu on 2022-05-06 17:03:03.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { LookupFormInput } from '@appformula/app-descriptor'
import { Nullable } from '@appformula/shared-foundation-x'
import { computed, makeObservable } from 'mobx'
import { BaseFormInputStore, BaseFormInputRepository } from './Base'

export type LookupFormInputRepository = BaseFormInputRepository<LookupFormInput>

export class LookupFormInputStore extends BaseFormInputStore<
    LookupFormInput,
    LookupFormInputRepository
> {
    get lookupTableId(): Nullable<string> {
        return this.object?.lookupTableId
    }

    get lookupColumnName(): Nullable<string> {
        return this.object?.lookupColumnName
    }

    get allowsMultipleSelection(): boolean {
        return this.object?.allowsMultipleSelection ?? false
    }

    constructor(repository: LookupFormInputRepository) {
        super(repository)
        makeObservable(this, {
            lookupTableId: computed,
            lookupColumnName: computed,
            allowsMultipleSelection: computed,
        })
    }

    setTableId(tableId: string, lookupColumnName: string): Promise<void> {
        throw Error()
    }
}
