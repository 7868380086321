//
//  FormInputRepository.ts
//
//  Created by Peter Vu on 2022-05-25 10:52:48.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { BaseFormInput } from '@appformula/app-descriptor'
import { BaseFormInputRepository } from '@appformula/app-studio-core'
import { Nullable } from '@appformula/shared-foundation-x'
import { Observable } from 'rxjs'
import { FirebaseRTDObjectRepository } from '../../ObjectRepository'

export class FirebaseFormInputRepository<I extends BaseFormInput>
    extends FirebaseRTDObjectRepository<I>
    implements BaseFormInputRepository<I>
{
    get inputId(): string {
        return this.objectId
    }
    get inputSnapshot(): Nullable<I> {
        return this.objectSnapshot
    }
    get input(): Observable<I> {
        return this.object
    }
}
