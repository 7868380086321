import { ComponentContainerPage } from '@appformula/app-descriptor'
import { makeObservable, computed, action } from 'mobx'
import { BasePageStore } from './Base'
import { ComponentContainerPageRepository } from './ComponentContainer.repo'
import { Nullable } from '@appformula/shared-foundation-x'
import {
    LexoRankContainer,
    LexoRankInsertionPosition,
} from '../helpers/LexoRankContainer'
import { nanoid } from 'nanoid'

export class ComponentContainerPageStore<
    C,
    P extends ComponentContainerPage<C>,
    R extends ComponentContainerPageRepository<C, P>
> extends BasePageStore<P, R> {
    get componentIds(): string[] {
        const components = this.object?.components ?? {}
        const componentsPosition = this.object?.componentsPosition ?? {}
        return Object.keys(components).sort((lhs, rhs) => {
            const lhsPosition = componentsPosition[lhs] ?? ''
            const rhsPosition = componentsPosition[rhs] ?? ''
            return lhsPosition.localeCompare(rhsPosition)
        })
    }

    constructor(repository: R) {
        super(repository)
        makeObservable(this, {
            componentIds: computed.struct,
            moveComponentId: action,
        })
    }

    addComponent(component: C): Promise<void> {
        return this.insertComponent(component, 'above', this.componentIds[0])
    }

    insertComponent(
        component: C,
        position: LexoRankInsertionPosition,
        targetComponentId: Nullable<string>
    ): Promise<void> {
        const newComponentId = nanoid(8)
        const componentRank = this.componentInsertionRank(
            position,
            targetComponentId
        )
        return this.repository.insertComponent(
            newComponentId,
            component,
            componentRank
        )
    }

    moveComponentId(
        sourceComponentId: string,
        position: LexoRankInsertionPosition,
        targetComponentId: Nullable<string>
    ): Promise<void> {
        const componentRank = this.componentInsertionRank(
            position,
            targetComponentId
        )
        if (this.object) {
            const componentPositions = this.object?.componentsPosition ?? {}
            componentPositions[sourceComponentId] = componentRank
            this.object.componentsPosition = componentPositions
        }
        return this.repository.setComponentPosition(
            sourceComponentId,
            componentRank
        )
    }

    removeComponentId(componentId: string): Promise<void> {
        return this.repository.removeComponent(componentId)
    }

    private componentInsertionRank(
        position: LexoRankInsertionPosition,
        targetComponentId: Nullable<string>
    ): string {
        const lexoRankContainer = new LexoRankContainer(
            this.componentIds,
            this.object?.componentsPosition ?? {}
        )
        return lexoRankContainer.insertionRank(position, targetComponentId)
    }
}
