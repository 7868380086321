//
//  TextInput.ts
//
//  Created by Peter Vu on 2022-05-06 17:04:55.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import { Binding, TextInput } from '@appformula/app-descriptor'
import { Nullable } from '@appformula/shared-foundation-x'
import { computed, makeObservable } from 'mobx'
import { ContentExportableComponentStore } from '.'
import { ControlComponentStore } from './Base'
import { PageComponentRepository } from './Base.repo'

export type TextInputRepository = PageComponentRepository<TextInput>

export class TextInputStore
    extends ControlComponentStore<TextInput>
    implements ContentExportableComponentStore
{
    get placeholder(): Nullable<Binding<string>> {
        return this.object?.placeholder
    }

    get initialText(): Nullable<Binding<string>> {
        return this.object?.initialText
    }

    constructor(repository: TextInputRepository) {
        super(repository)
        makeObservable(this, {
            placeholder: computed.struct,
            initialText: computed.struct,
        })
    }
    get exportPropNames(): string[] {
        return ['placeholder', 'initialText']
    }
    exportedData(props: keyof TextInput): string[] {
        return [this.componentId, props]
    }
}
