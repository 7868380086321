//
//  Base.ts
//
//  Created by Peter Vu on 2022-05-06 17:02:48.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import {
    Binding,
    ConstantBinding,
    FormInput,
    FormInputType,
} from '@appformula/app-descriptor'
import { Nullable } from '@appformula/shared-foundation-x'
import { computed, makeObservable } from 'mobx'
import { Observable } from 'rxjs'
import { ObservableObjectStore } from '../../../ObservableObjectStore'

export class BaseFormInputStore<
    I extends FormInput,
    R extends BaseFormInputRepository<I>
> extends ObservableObjectStore<I> {
    protected repository: R

    get componentId(): string {
        return this.repository.inputId
    }

    get referenceName(): Nullable<string> {
        return this.object?.referenceName
    }

    get targetColumnName(): Nullable<string> {
        return this.object?.targetColumnName
    }

    get titleName(): Nullable<string> {
        return this.object?.titleName
    }

    get isHidden(): Binding<boolean> {
        return this.object?.isHidden ?? ConstantBinding.boolean(false)
    }

    get inputType(): FormInputType {
        return this.object?.inputType ?? 'singleLineText'
    }

    get required(): boolean {
        return this.object?.required ?? false
    }

    constructor(repository: R) {
        super(repository.input, repository.inputSnapshot)
        this.repository = repository
        makeObservable(this, {
            targetColumnName: computed,
            isHidden: computed,
            inputType: computed,
            required: computed,
            referenceName: computed,
            titleName: computed,
        })
    }

    changeReferenceName(name: string): Promise<void> {
        return this.repository.set('referenceName', name)
    }

    async set<K extends keyof I>(key: K, value: I[K]): Promise<void> {
        this.willSet(key, value)
        await this.repository.set(key, value)
        this.didSet(key, value)
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected willSet<K extends keyof I>(key: K, newValue: I[K]): void {
        return
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected didSet<K extends keyof I>(key: K, newValue: I[K]): void {
        return
    }
}

export interface BaseFormInputRepository<I extends FormInput> {
    get inputId(): string
    get inputSnapshot(): Nullable<I>
    get input(): Observable<I>

    set<K extends keyof I>(key: K, value: I[K]): Promise<void>
}
