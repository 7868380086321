import { Nullable } from '@appformula/shared-foundation-x'
import { LexoRank } from 'lexorank'
import * as _ from 'lodash'

export type LexoRankInsertionPosition = 'below' | 'above'

// TODO: Add unit test for this
export class LexoRankContainer {
    lexoRankTable: Record<string, string>
    itemIdentifiers: string[]

    constructor(itemIdentifiers: string[], lexoRankTable: Record<string, string>) {
        this.itemIdentifiers = itemIdentifiers
        this.lexoRankTable = lexoRankTable
    }

    insertionRank(position: LexoRankInsertionPosition, targetItemId: Nullable<string>): string {
        if (!targetItemId) {
            switch (position) {
                case 'above': {
                    // above null is last item in the list
                    const lastItemId = _.last(this.itemIdentifiers)
                    if (lastItemId) {
                        const lastItemRank = this.lexoRankTable[lastItemId]
                        if (!lastItemRank) {
                            throw Error(`Item rank isn't associated with item ${lastItemId}`)
                        }
                        return LexoRank.min().between(LexoRank.parse(lastItemRank)).toString()
                    }
                    break
                }

                case 'below': {
                    // below null is first item in the list
                    const firstItemId = _.first(this.itemIdentifiers)
                    if (firstItemId) {
                        const firstItemRank = this.lexoRankTable[firstItemId]
                        if (!firstItemRank) {
                            throw Error(`Item rank isn't associated with item ${firstItemId}`)
                        }
                        return LexoRank.parse(firstItemRank).between(LexoRank.max()).toString()
                    }
                }
            }

            // If we don't have last item or first item, return a middle
            return LexoRank.middle().toString()
        }

        const targetComponentIndex = this.itemIdentifiers.indexOf(targetItemId)
        if (targetComponentIndex < 0) {
            throw Error(`Target component id ${targetItemId} is not exist in the tree`)
        }

        const targetComponentRank = this.lexoRankTable[targetItemId]
        if (!targetComponentRank) {
            throw Error(`The targetComponent doesn't have position associated. Target component Id: ${targetItemId}`)
        }

        let targetRank: LexoRank = LexoRank.middle()

        switch (position) {
            case 'above': {
                const prevComponentId = this.itemIdentifiers[targetComponentIndex - 1]
                if (prevComponentId) {
                    const prevComponentRank = LexoRank.parse(this.lexoRankTable[prevComponentId] ?? '')
                    targetRank = prevComponentRank.between(LexoRank.parse(targetComponentRank))
                } else {
                    targetRank = LexoRank.parse(targetComponentRank).genPrev()
                }
                break
            }
            case 'below': {
                const nextComponentId = this.itemIdentifiers[targetComponentIndex + 1]
                if (nextComponentId) {
                    const nextComponentRank = LexoRank.parse(this.lexoRankTable[nextComponentId] ?? '')
                    targetRank = nextComponentRank.between(LexoRank.parse(targetComponentRank))
                } else {
                    targetRank = LexoRank.parse(targetComponentRank).genNext()
                }
            }
        }

        return targetRank.toString()
    }
}
