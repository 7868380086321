//
//  Text.ts
//
//  Created by Peter Vu on 2022-05-06 17:04:53.
//  Copyright © 2022 Unstatic Co., Ltd. All rights reserved.
//

import {
    Binding,
    TextAlignment,
    TextComponent,
    TextStyle,
} from '@appformula/app-descriptor'
import { Nullable } from '@appformula/shared-foundation-x'
import { computed, makeObservable } from 'mobx'
import { ContentExportableComponentStore } from '.'
import { BasePageComponentStore } from './Base'
import { PageComponentRepository } from './Base.repo'

export type TextComponentRepository = PageComponentRepository<TextComponent>

export class TextComponentStore
    extends BasePageComponentStore<TextComponent, TextComponentRepository>
    implements ContentExportableComponentStore
{
    get text(): Nullable<Binding<string>> {
        return this.object?.text
    }

    get style(): TextStyle {
        return this.object?.style ?? 'paragraph'
    }

    get textAlignment(): TextAlignment {
        return this.object?.textAlignment ?? 'leading'
    }

    constructor(repository: TextComponentRepository) {
        super(repository)

        makeObservable(this, {
            text: computed.struct,
            style: computed,
            textAlignment: computed,
        })
    }
    get exportPropNames(): string[] {
        return ['text']
    }

    exportedData(props: keyof TextComponent): string[] {
        return [this.componentId, props]
    }
}
